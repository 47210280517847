import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import styled from "styled-components/macro";
import { Connect } from '@stacks/connect-react';
// import ReactBlockstack, { useBlockstack, didConnect } from 'react-blockstack';
import '../node_modules/react-keyed-file-browser/dist/react-keyed-file-browser.css'
import './App.css';
import axios from 'axios'
import { GlobalProvider, GlobalContext } from './context/GlobalState';
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

// import { appConfig } from './constants'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ReactComponent as DragDropIcon } from './vendor/locker.svg'
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import {
  Grid,
  Typography
} from "@material-ui/core";

import createTheme from "./theme";
import Routes from "./routes/Routes";

const DragContent = styled(Grid)`
  border: solid 6px #0a223d;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});
var chunkSize = 1024*1024; // bytes
var timeout = 10; // millisec
const api_host = process.env.REACT_APP_LAYER_BACKEND_HOST;
const api_authToken = localStorage.getItem('auth_token');

const MainDiv = () => {
  
  const [drag, setDrag] = useState(false);
  const [fileData, setFileData] = useState();
  const [uploadLoad, setUploadLoad] = useState(false);
  const { dispatch, files, selected_folder, getFiles, getFolder } = useContext(GlobalContext);

  useEffect(() => {
    getFiles()
  }, [])

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
    console.log('file enter')
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    console.log('file leave')
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    console.log('file drga over')
  };

  function loading(file, callbackProgress, callbackFinal) {
    //var chunkSize  = 1024*1024; // bytes
    var offset = 0;
    var size = chunkSize;
    var partial;
    var index = 0;

    if (file.size === 0) {
      callbackFinal();
    }
    while (offset < file.size) {
      partial = file.slice(offset, offset + size);
      var reader = new FileReader;
      reader.size = chunkSize;
      reader.offset = offset;
      reader.index = index;
      reader.onload = function (evt) {
          
        callbackRead(this, file, evt, callbackProgress, callbackFinal);
      };
      reader.readAsArrayBuffer(partial);
      offset += chunkSize;
      index += 1;
    }
  }

  function callbackRead(obj, file, evt, callbackProgress, callbackFinal) {
    if (true) {
      callbackRead_buffered(obj, file, evt, callbackProgress, callbackFinal);
    } else {
      callbackRead_waiting(obj, file, evt, callbackProgress, callbackFinal);
    }
  }

  var lastOffset = 0;
  var chunkReorder = 0;
  var chunkTotal = 0;
  // time reordering
  function callbackRead_waiting(reader, file, evt, callbackProgress, callbackFinal) {
    if (lastOffset === reader.offset) {
      console.log("[", reader.size, "]", reader.offset, '->', reader.offset + reader.size, "");
      lastOffset = reader.offset + reader.size;
      callbackProgress(evt.target.result);
      if (reader.offset + reader.size >= file.size) {
        lastOffset = 0;
        callbackFinal();
      }
      chunkTotal++;
    } else {
      console.log("[", reader.size, "]", reader.offset, '->', reader.offset + reader.size, "wait");
      setTimeout(function () {
        callbackRead_waiting(reader, file, evt, callbackProgress, callbackFinal);
      }, timeout);
      chunkReorder++;
    }
  }
  // memory reordering
  var previous = [];
  function callbackRead_buffered(reader, file, evt, callbackProgress, callbackFinal) {
    chunkTotal++;

    if (lastOffset !== reader.offset) {
      // out of order
      console.log("[", reader.size, "]", reader.offset, '->', reader.offset + reader.size, ">>buffer");
      previous.push({ offset: reader.offset, size: reader.size, result: reader.result });
      chunkReorder++;
      return;
    }

    function parseResult(offset, size, result) {
      lastOffset = offset + size;
      callbackProgress(result);
      if (offset + size >= file.size) {
        lastOffset = 0;
        callbackFinal();
      }
    }

    // in order
    console.log("[", reader.size, "]", reader.offset, '->', reader.offset + reader.size, "");
    parseResult(reader.offset, reader.size, reader.result);

    // resolve previous buffered
    var buffered = [{}]
    while (buffered.length > 0) {
      buffered = previous.filter(function (item) {
        return item.offset === lastOffset;
      });
      buffered.forEach(function (item) {
        console.log("[", item.size, "]", item.offset, '->', item.offset + item.size, "<<buffer");
        parseResult(item.offset, item.size, item.result);
        previous.remove(item);
      })
    }

  }

  Array.prototype.remove = Array.prototype.remove || function (val) {
    var i = this.length;
    while (i--) {
      if (this[i] === val) {
        this.splice(i, 1);
      }
    }
  };
  
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    console.log('file Droppedd ')
    setDrag(false);
    // const file = e.dataTransfer.files[0];
    let fils = e.dataTransfer.files;
    
    for (let i = 0; i < fils.length; i++) {
      let file = fils.item(i);
      send_file(file);
    }
    
  };

  const send_file = (file) => {
    setFileData(file);
    dispatch({
      type: 'LOADING',
      payload: true
    })
    // uploadFiles(file);
    
    var CryptoJS = require("crypto-js");
    
     
    var SHA256 = CryptoJS.algo.SHA256.create();
    var counter = 0;
    var self = this;

    var timeStart = new Date().getTime();
    var timeEnd = 0;
    

    loading(file,
        function (data) {
            var wordBuffer = CryptoJS.lib.WordArray.create(data);
            SHA256.update(wordBuffer);
          counter += data.byteLength;
          
            //console.log((( counter / file.size)*100).toFixed(0) + '%');
        }, function (data) {
            //console.log('100%');
            var encrypted = SHA256.finalize().toString();
          var currentLocation = window.location.pathname;
          const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
          var bodyFormData = new FormData();
          bodyFormData.append('name', file.name);
          bodyFormData.append('file', file);
          if (folder_id.match(/^[0-9]+$/)) {
            bodyFormData.append('folder_id', folder_id )
          }
          
          bodyFormData.append('chunked', 0);
          bodyFormData.append('file_hash', encrypted);
          // uploadFiles(bodyFormData);
          axios({
            method: "post",
            url: api_host + '/storage/file',
            data: bodyFormData,
            headers: {
              'Accept': 'application/json',
              'Access-Control-Allow-Credentials': true,
              'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
              'Authorization': `Bearer ${api_authToken}`,
              "Content-Type": "multipart/form-data"
              }
          })
          .then(function (response) {
            console.log(response);
            if (response.status === 201) {
              let type = file.type;
              if (!type.toString().includes('video')) {
                response.data.data['image'] = URL.createObjectURL(file)
              }
              if (!response.data.data.file_folder_id) {
                
                let fls = files;
                response.data.data['video_stream_urls'] = 2
                  fls.unshift(response.data.data);
                  dispatch({
                    type: 'UPDATE_FILES',
                    payload: fls
                  });
              } else {
                let folder = selected_folder;
                  console.log(folder);
                  response.data.data['video_stream_urls'] = 1
                  folder.files.unshift(response.data.data);
                  dispatch({
                    type: 'SET_ACTIVE',
                    payload: folder
                  })
              }
              
              
              // for (var pair of bodyFormData.entries()) {
              //   if (pair[0] === 'folder_id') {
              //     let folder = selected_folder;
              //     console.log(folder);
              //     response.data.data['video_stream_urls'] = 1
              //     folder.files.unshift(response.data.data);
              //     dispatch({
              //       type: 'SET_ACTIVE',
              //       payload: folder
              //     })
              //   }
              // }
              dispatch({
                type: 'LOADING',
                payload: false
              })
              var currentLocation = window.location.pathname;
              const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
              if (folder_id.match(/^[0-9]+$/)) {
                getFolder(folder_id)
                
              } else {
                getFiles()
              }
            //   var currentLocation = window.location.pathname;
            //   if (currentLocation.indexOf("folder") === 1) {
            //     const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
            //     // history.push(`/folder/${folder_id}`);
            //     // window.location.href = `/folder/${folder_id}`;
            //   } else {
            //     // history.push(`/dashboard/mylocker`);
            //     // window.location.href = `/dashboard/mylocker`;
            //   }
            //   // let files = state.files;
            //   // files.unshift(response.data.data);
             }
            
          })
          .catch(function (response) {
            console.log(response);
          });
            timeEnd = new Date().getTime();
    });
  }
  return (
    <div
      style={{ width: 'calc(100vw)', height: 'calc(100vh)' }}
      // onDrop={e => handleDrop(e)}
      // onDragEnter={e => handleDragEnter(e)}
      // onDragOver={e => handleDragOver(e)}
      // onDragLeave={e => handleDragLeave(e)}
      >
        {!drag ?
          <Routes /> :
          <DragContent
          style={{ width: 'calc(100vw - 16px)', height: 'calc(100vh - 16px)' }}
          className="drop_item" item xs={12}></DragContent>
        }
    </div>
  )
}

const App = () => {
  const theme = useSelector((state) => state.themeReducer);
  const history = useHistory();

  
    

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="Layer Drive - NFT Management"
        defaultTitle="Layer Drive - NFT Management"
      />
      <Connect >
        <GlobalProvider>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiThemeProvider
                theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)} >
                  <MainDiv />
                  {/* <div
                    style={{ width: 'calc(100vw - 16px)', height: 'calc(100vh - 16px)' }}
                    onDrop={e => handleDrop(e)}
                    onDragEnter={e => handleDragEnter(e)}
                    onDragOver={e => handleDragOver(e)}
                    onDragLeave={e => handleDragLeave(e)}
                  >
                    {!drag ?
                      <Routes /> :
                      <DragContent className="drop_item" item xs={12}>
                      </DragContent>
                    }
                  </div> */}
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </GlobalProvider>
      </Connect>
    </React.Fragment>
  );
}

export default App;
