import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../vendor/layer-loader'

const LoadingLocker = () => {
  return (
    <>
      <Lottie
        options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
        height={200}
        width={200}
      />  
    </>
  )
}

export default LoadingLocker;