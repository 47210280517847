import React, { useContext, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components/macro'
import { GlobalContext } from '../context/GlobalState';
import LoadingLocker from './LoadingLocker';
import { ReactComponent as FolderIcon } from '../vendor/carpet-group-blue.svg'
import { ReactComponent as PdfIcon } from '../vendor/file-pdf.svg';
import { ReactComponent as EpsIcon } from '../vendor/file-eps.svg';

import {
  List,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  Typography,
  LinearProgress,
  Button as MuiButton,
  Link as MuiLink,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";
import { LinkedIn, InsertDriveFile } from '@material-ui/icons';

const ListItem = styled(MuiListItem)(spacing);
const ListItemNotButton = styled(MuiListItem)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding-left: 0;
  padding-right: 0;
  svg {
    width: 50px;
    height: 50px;
  }
`;

const FileDetails = styled.div`
  margin-left: 10px;
  width: 280px;
  p {
    white-space: nowrap;
  }
`;

const LinkBlock = styled(MuiLink)`
  color: #1588d0
`;

const FileContent = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    position: absolute;
    font-size: 8px;
    color: #fff;
    bottom: 0;
  }
`;


function FileIcon({ extension }) {
  if (extension === 'eps') {
    return (
      <EpsIcon />
    )
  } else if (extension === 'pdf') {
    return (
      <PdfIcon />
    )
  } else {
    return (
      <FileContent>
        <InsertDriveFile style={{ color: 'rgb(86, 99, 231)' }} />
        <p>{ extension.slice(extension.lastIndexOf('/') + 1) }</p>
      </FileContent>
      
    )
  }
  
}

function FilesUploaded() {
  const { files_uploaded, upload_message } = useContext(GlobalContext);
  return (
    <>
      {files_uploaded.length > 0 ?
        <>
            <List mb={4}>
            {files_uploaded.map((file, index) => (
              <ListItemNotButton key={index} >
                <FileIcon extension={file.type} />
                <FileDetails ml={3}>
                  {file.error ? 
                    file.error.file_name
                    : 
                    <>
                      <Typography variant="body2">
                        {file.name}
                      </Typography>
                      <Typography variant="body2">
                        {file.upload < 100 ? `Uploading ${file.upload}%` : 'Upload Complete'}
                      </Typography>
                      <LinearProgress variant="buffer" value={file.upload} valueBuffer={file.upload} />
                    </>
                    
                    }
                </FileDetails>
              </ListItemNotButton>
            ))}
            </List>
        </>
        : 
        <div style={{width: '100%', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', padding: '50px 0'}}>
          <p style={{marginTop: '0px'}}>{upload_message}</p>
        </div>
      }
    </>
  )
}

export default FilesUploaded;