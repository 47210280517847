import { Person } from '@stacks/profile';

export default (state, action) => {
  switch (action.type) {
    case 'UPLOAD_FILES':
      return {
        ...state,
        // files_uploaded: state.files_uploaded.concat(action.payload)
        files_uploaded: action.payload
      }
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        person: new Person(action.payload.profile)
      }
    case 'UPDATE_FILES':
      return {
        ...state,
        files: action.payload
      }
    case 'UPDATE_ALLFILES':
      return {
        ...state,
        allFiles: action.payload
      }
    case 'UPDATE_ARCHIVES':
      return {
        ...state,
        archives: action.payload
      }
    case 'UPDATE_PROFILE':
      return {
        ...state,
        profile: action.payload
      }
    case 'SET_INVITES':
      return {
        ...state,
        invites: action.payload
      }
    case 'LOADING':
      return {
        ...state,
        loading: action.payload
      }
    case 'UPDATE_FOLDERS':
      return {
        ...state,
        folders: action.payload
      }
    case 'UPDATE_ARCHIVES':
      return {
        ...state,
        archives: action.payload
      }
    case 'SET_FOLDER':
      return {
        ...state,
        active_folder: action.payload
      }
    case 'SET_ACTIVE':
      return {
        ...state,
        selected_folder: action.payload
      }
    case 'UPDATE_HASH':
      return {
        ...state,
        tx_hash: action.payload
      }
    case 'UPLOAD_MESSAGE':
      return {
        ...state,
        upload_message: action.payload
      }
    case 'SET_UPLOADING_FILES':
      return {
        ...state,
        uploading_files: !state.uploading_files
      }
    case 'SET_UPLOADING':
      return {
        ...state,
        uploading_files: false
      }
    case 'SET_UPLOAD':
      return {
        ...state,
        uploading_files: true
      }
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        message: action.payload
      }
    case 'UPDATE_IPFS':
      return {
        ...state,
        ipfs: action.payload
      }
    case 'UPDATE_DATA':
      return {
        ...state,
        data: action.payload
      }
    case 'UPDATE_HISTORY':
      return {
        ...state,
        data: action.payload
      }
    case 'SET_FIREBASE':
      return {
        ...state,
        firebase_message: action.payload
      }
    case 'VIDEO_TUTORIAL':
      return {
        ...state,
        video: action.payload
      }
    case 'UPDATE_LINKS':
      return {
        ...state,
        links: action.payload
      }
    default:
      return state;
  }
}