import React, { useContext, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Typography,
  Paper,
  Button,
  Menu,
  MenuItem
} from '@material-ui/core'
import {
  ArrowDropDown
} from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import { userSession } from '../authentication';
import { GlobalContext } from '../context/GlobalState';
import FeedItem from './FeedItem';

const FeedContainer = styled.div`
  background-color: ${props => props.theme.palette.background.default};
  border: solid 1px #d7dde4;
  height: 100%;
  border-radius: 5px;
`;

const FeedHeader = styled.div`
  padding: 15px 5px;
  text-align: center;
  border-bottom: solid 1px #d7dde4; 
`;

const FeedBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
`;

const MenuButton = styled(Button)`
  background: transparent;
  color: #000000;
  padding: 0;
  width: 20px;
  height: 20px;
  min-width: 10px;
  margin-left: 3px;
`;

const BreakText = styled(Typography)`
  word-break: break-all;
`;

function Feed() {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { user, invites } = useContext(GlobalContext);
  console.log(invites)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    // pages/settings
    history.push("/pages/settings");
  }

  return (
    <FeedContainer>
      <FeedHeader>
        <BreakText variant="body2">
          {user && user.username && user.username.length > 0 && user.username.split('.')[0]}
          <MenuButton
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="primary"
          >
            <ArrowDropDown />
          </MenuButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenProfile}>Profile</MenuItem>
            <MenuItem onClick={() => {
              userSession.signUserOut();
              localStorage.removeItem('auth_token');
              window.location = '/';
            }}>Logout</MenuItem>
          </Menu>
        </BreakText>
      </FeedHeader>
      <FeedBody>
        <Typography variant="h4" gutterBottom>
          Locker feed
        </Typography>
        {invites.length > 0
          ?
          <>
            {invites.map(invite => (
              <FeedItem key={invite.id} invite={invite} />
            ))}
          </>
          :
          <Typography variant="body2" align="center">
            No insight available.
            Your locker files have never been accessed.
          </Typography>
        }

      </FeedBody>
    </FeedContainer>
  )
}
export default Feed;