import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import {
  stringAsciiCV,
  AnchorMode,
  PostConditionMode
} from '@stacks/transactions';
import { StacksTestnet, StacksMainnet } from '@stacks/network';
import { openContractCall } from '@stacks/connect';
//import { Person } from '@stacks/profile';
import { encryptECIES, decryptECIES, signECDSA, verifyECDSA } from '@stacks/encryption';

const CONTRACT_ADDRESS_AUTH = process.env.REACT_APP_CONTRACT_ADDRESS_AUTH;
const CONTRACT_NAME_AUTH = process.env.REACT_APP_CONTRACT_NAME_AUTH;
const network = (process.env.REACT_APP_STACKS_NETWORK ?? 'testnet') === 'mainnet' ? new StacksMainnet() : new StacksTestnet();
const appConfig = new AppConfig(['store_write', 'publish_data']);

export const userSession = new UserSession({ appConfig });


// export function authenticate() {
//   showConnect({
//     appDetails: {
//       name: 'Locker - Layer Technologies',
//       icon: window.location.origin + '/favicon.ico',
//     },
//     redirectTo: '/dashboard/default',
//     finished: () => {
//       window.location.reload();
//     },
//     userSession: userSession,
//     registerSubdomain: true
//   });
// }
export function authenticate(func) {
  showConnect({
    network,
    appDetails: {
      name: 'Locker - Layer Technologies',
      icon: window.location.origin + '/favicon.ico',
    },
    onFinish: () => {
      func();
    },
    userSession: userSession,
  });
}

export async function validateAuth(privateKey, cipherObj, resolve) {
  console.log('validate-auth');
  console.log(CONTRACT_ADDRESS_AUTH)
  console.log(CONTRACT_NAME_AUTH)
  const deciphered = await decryptECIES(privateKey, cipherObj);
  const BigNum = require('bn.js');
  const options = {
    contractAddress: CONTRACT_ADDRESS_AUTH,
    contractName: CONTRACT_NAME_AUTH,
    functionName: 'validate-auth',
    network,
    functionArgs: [stringAsciiCV(deciphered)],
    postConditions: [],
    postConditionMode: PostConditionMode.Deny,
    sponsored: true,
    anchorMode: AnchorMode.Any,
    // fee: new BigNum(200),
    appDetails: {
      name: 'Locker - Layer Technologies',
      icon: window.location.origin + '/favicon.ico',
    },
    onFinish: (data) => {


      const stacksTransaction = data.stacksTransaction;
      resolve(stacksTransaction);

    },
  };
  
  await openContractCall(options);
}

export async function sponsorMint(privateKey, functionArgs, functionName, resolve) {
  // const deciphered = await decryptECIES(privateKey);
  const BigNum = require('bn.js');
  const options = {
    contractAddress: CONTRACT_ADDRESS_AUTH,
    contractName: CONTRACT_NAME_AUTH,
    functionName,
    network,
    functionArgs,
    postConditions: [],
    postConditionMode: PostConditionMode.Deny,
    sponsored: true,
    anchorMode: AnchorMode.Any,
    // fee: new BigNum(200),
    appDetails: {
      name: 'Locker - Layer Technologies',
      icon: window.location.origin + '/favicon.ico',
    },
    onFinish: (data) => {


      const stacksTransaction = data.stacksTransaction;
      resolve(stacksTransaction);

    },
  };
  
  await openContractCall(options);
}


export function getUserData() {
  return userSession.loadUserData();
}

// export function getPerson() {
//   return new Person(getUserData().profile);
// }
