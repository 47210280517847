import React from 'react'
import styled from 'styled-components/macro'

import {
  Typography
} from '@material-ui/core'

import { ReactComponent as DragDropIcon } from '../vendor/folder.svg'

const DragContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 35px 20px;
  border-radius: 7px;
  border: dashed 2px #d7dde4;
`;

function DragArea() {
  return (
    <DragContent>
      <DragDropIcon />
      <Typography variant="body2">
        Drag your files here
      </Typography>
    </DragContent>
  )
}

export default DragArea;