import React, { createContext, useReducer, useEffect, useState } from 'react'
import AppReducer from './AppReducer';
import { userSession, getUserData, sponsorMint } from '../authentication';
import { Storage } from '@stacks/storage';
import { StacksTestnet, StacksMainnet } from '@stacks/network';
import { openContractCall } from '@stacks/connect';
import firebase from '../firebase';
import { signECDSA } from '@stacks/encryption';
import axios from 'axios'
// import toReadableStream from 'to-readable-stream';
import {
  uintCV,
  intCV,
  bufferCV,
  stringAsciiCV,
  stringUtf8CV,
  standardPrincipalCV,
  trueCV,
  listCV,
  bufferCVFromString,
  PostConditionMode,
  someCV,
  tupleCV,
  noneCV,
  callReadOnlyFunction,
  cvToValue,
  falseCV
} from '@stacks/transactions';
import { v4 as uuid } from 'uuid';
import generateHash from '../lib';
import { Readable } from 'stream'
import sha256 from 'crypto-js/sha256';
import MintToCollection from '../components/MintToCollection';
const fs = require('fs');
// export const CONTRACT_ADDRESS = 'ST4WC7XV46Y2M6SDD5G31H8BHK8GMJX13XGEYNS8';
// export const CONTRACT_NAME = 'outdoor-silver-spider';
export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
export const CONTRACT_NAME = process.env.REACT_APP_CONTRACT_NAME;
export const STACKS_NETWORK = (process.env.REACT_APP_STACKS_NETWORK ?? 'testnet') === 'mainnet' ? new StacksMainnet() : new StacksTestnet();
// const storage = new Storage({ userSession });
const pinataSDK = require('@pinata/sdk');
const pinata = pinataSDK('f399564e2f90501c5ff4', '729e78fbcb48b2304c2942d6741d632a0c4bd357eaf5b4a428fa0f6eb34029e7');
const api_host = process.env.REACT_APP_LAYER_BACKEND_HOST;
const api_authToken = localStorage.getItem('auth_token');
let chunks;
let chunk;
const chunkSize = 20971520;
let urls_list = [];
// pinata API KEY = f399564e2f90501c5ff4
// pinata API SECRET KEY = 729e78fbcb48b2304c2942d6741d632a0c4bd357eaf5b4a428fa0f6eb34029e7
// pinata secret access token = eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI4OTU1Mzk3Mi02MDRjLTRhNWQtOTViOS05ZDFiOGFjODdmMzgiLCJlbWFpbCI6ImdhcnlAaGV5bGF5ZXIuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZX0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImYzOTk1NjRlMmY5MDUwMWM1ZmY0Iiwic2NvcGVkS2V5U2VjcmV0IjoiNzI5ZTc4ZmJjYjQ4YjIzMDRjMjk0MmQ2NzQxZDYzMmEwYzRiZDM1N2VhZjViNGE0MjhmYTBmNmViMzQwMjllNyIsImlhdCI6MTYzNDU1NjcxNH0.M1SqaGH4TFcUKf8sEwtVPzn4tfX3J-tvVpYy82zv-Uw

const initialState = {
  files_uploaded: [],
  files: [],
  allFiles: [],
  archives: [],
  user: {},
  person: [],
  profile: {},
  invites: [],
  loading: false,
  folders: [],
  active_folder: '',
  tx_hash: '',
  uploading_files: false,
  mint_nft: {
    public: false,
    template: '',
    url: ''
  },
  ipfs: '',
  message: 'Saving NFT to IPFS!!',
  data: '',
  history: [],
  firebase_message: 'loading',
  video: '',
  links: {},
  selected_folder: '',
  upload_message: ''
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const [txId, settxId] = useState();
  const [parts, setParts] = useState([]);
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    console.log('checking login');
    const authToken = localStorage.getItem('auth_token');
    console.log(authToken)
    if (authToken && authToken.length > 1) {
      setUser({
        auth: authToken,
      })
    }

    // if (userSession.isSignInPending()) {
    //   userSession.handlePendingSignIn().then((userData) => {
    //     setUser(getUserData());
    //     localStorage.removeItem('auth_token');
    //   });
    // } else if (userSession.isUserSignedIn()) {
    //   console.log('signed in')
    //   setUser(userSession.loadUserData());
    //   localStorage.removeItem('auth_token');
    // }

    
  }, []);

  async function viewProfile (api_token) {
    const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
    try {
      let res = await axios.get(host + '/me', {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${api_token}`
        },
      });
      console.log(res);
      if (res.status === 200) {
        let user = res.data.data;
        user["auth"] = localStorage.getItem('auth_token');;
        console.log(res.data.data);
        setUser(user);
        return user;
        // loginUser(api_token)
      }
      
    } catch (error) {
      return 'error'
      console.log(error);
    }
  }

  async function setUser(user) {
    dispatch({
      type: 'SET_USER',
      payload: user
    })
  }

  async function sendFile(hash, result) {
    try {
      let res = await axios.post(api_host + '/storage/file', {
        file: result,
        file_hash: hash,
        name: 'name',
        chunked: 0
      },{
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Authorization': `Bearer ${api_authToken}`
          },
        }, { withCredentials: true });
        console.log(res)
      } catch (error) {
        console.log(error);
      }
  }
  
  async function uploadFiles2(bodyFormData, image, file, limit) {
    chunks = Math.ceil(file.size / chunkSize, chunkSize);
    chunk = 0;
    console.log(limit)
    let files_uploaded = state.files_uploaded;
    let upload_urls;
    let id = uuid();
    id = generateHash(id);
    let new_file = {
      id,
      name: file.name,
      size: file.size,
      type: file.type,
      upload: 0,
      file: file
    };
    files_uploaded.unshift(new_file);
    dispatch({
      type: 'UPLOAD_FILES',
      payload: files_uploaded
    });

    axios({
      method: "post",
      url: api_host + '/storage/file',
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Authorization': `Bearer ${api_authToken}`,
        "Content-Type": "multipart/form-data"
        }
    })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          
          upload_urls = response.data.upload_urls;
          let percentage = (chunk * 100) / chunks;
          console.log(percentage);
          upload_urls.map(url => {
            urls_list.push(url.url);
          })
            files_uploaded.map((f) => {
                if (f.id === id) {
                  f['upload'] = percentage.toFixed(0);
                  dispatch({
                    type: 'UPLOAD_FILES',
                    payload: files_uploaded
                  })
                }
              } )
          uploadToDigitalOcean(0, file, response.data.id, id);
          // chunks = Math.ceil(file.size / chunkSize, chunkSize);
          // chunk = 1;
          // if (chunks > 1) {
          //   const percentage = (chunk * 100) / chunks
          //   files_uploaded.map((f) => {
          //       if (f.id === id) {
          //         f['upload'] = percentage.toFixed(0);
          //         dispatch({
          //           type: 'UPLOAD_FILES',
          //           payload: files_uploaded
          //         })
          //       }
          //     } )
          //     if (chunk === (chunks - 1)) {
          //       uploadChunck(response.data.data.id, file, 1, id);
          //     } else {
          //       uploadChunck(response.data.data.id, file, 0, id);
          //     }
          //   } else {
          //     files_uploaded.map((f) => {
          //       if (f.id === id) {
          //         f['upload'] = 100;
          //         console.log(files_uploaded)
          //         dispatch({
          //           type: 'UPLOAD_FILES',
          //           payload: files_uploaded
          //         })
          //         uploadToDigitalOcean(url.url, file, response.data.id)
          //       }
          //     } )
          //   }
          
          

        }

        
        
        
        
      })
      .catch(function (error) {
        console.log(error.response);
        files_uploaded.map((f) => {
          if (f.id === id) {
            f['error'] = error.response.data.errors;
            dispatch({
              type: 'UPLOAD_FILES',
              payload: files_uploaded
            })
          }
        })
      });
  }
  async function uploadToDigitalOcean(partN, file, file_id, id) {
    let files_uploaded = state.files_uploaded;
    var offset = chunk * chunkSize;
    var slice;
    var file_copy = file;
    if (urls_list.length > 1) {
      if (file) {
        slice = file_copy.slice(offset, offset + chunkSize);
        console.log(slice);
      }
      
    } else {
      slice = file
    }
    console.log(urls_list)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", file.type);
    myHeaders.append("Access-Control-Expose-Headers", "ETag");
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: slice,
      redirect: 'follow'
    };
    // parts: [{"ETag": "etag from S3 API", "PartNumber": 1}, {"ETag": "ETag for part 2 from S3 API", "PartNumber": 2}]
    if (urls_list.length > 0 && urls_list[partN]) {
      fetch(urls_list[partN], requestOptions )
        .then(response => {
          const percentage = (chunk * 100) / chunks
            // dispatch({
            //   type: 'UPLOAD_MESSAGE',
            //   payload: `Uploading ${percentage.toFixed(0)} %`
            // })
            files_uploaded.map((f) => {
              if (f.id === id) {
                f['upload'] = percentage.toFixed(0);
                console.log(files_uploaded)
                dispatch({
                  type: 'UPLOAD_FILES',
                  payload: files_uploaded
                })
              }
            } )
        for (var pair of response.headers.entries()) {
          if (pair[0] === 'etag') {
            let etag = pair[1].replace(/"/g, '');
            parts.push({ETag: etag, PartNumber: partN + 1})
          }
        }
        chunk++;
        if (urls_list.length - 1 === partN) {
          completeUpload(file_id, id)
        } else {
          uploadToDigitalOcean(partN + 1, file, file_id, id);
        }
        
      })
      .catch(error => console.log('error', error));
    }
    
  }

  async function completeUpload(file_id, id) {
    let files_uploaded = state.files_uploaded;
    const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
    const authToken = localStorage.getItem('auth_token');
    console.log(parts)
    let parts_list = []
    if (parts.length > 1) {
      parts_list = parts
    } 
    try {
      let res = await axios.post(host + `/storage/file/${file_id}/complete`, {
        parts: parts
      },{
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${authToken}`
        },
      }, { withCredentials: true });
      console.log(res);
      if (res.status === 200) {
        urls_list = [];
        setParts([]);
        const percentage = (chunk * 100) / chunks
            files_uploaded.map((f) => {
              if (f.id === id) {
                f['upload'] = 100;
                console.log(files_uploaded)
                dispatch({
                  type: 'UPLOAD_FILES',
                  payload: files_uploaded
                })
              }
            } )
      }
      
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function uploadFiles(bodyFormData, image, file, limit) {
    // let offset = limit;
    let files_uploaded = state.files_uploaded;
    let id = uuid();
    id = generateHash(id);
    let new_file = {
      id,
      name: file.name,
      size: file.size,
      type: file.type,
      upload: 0,
      file: file
    };
    files_uploaded.unshift(new_file);
    dispatch({
      type: 'UPLOAD_FILES',
      payload: files_uploaded
    })
      // dispatch({
      //   type: 'SET_UPLOADING',
      // })

      // dispatch({
      //   type: 'LOADING',
      //   payload: true
      // })
    
    axios({
      method: "post",
      url: api_host + '/storage/file',
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Authorization': `Bearer ${api_authToken}`,
        "Content-Type": "multipart/form-data"
        }
    })
      .then(function (response) {
        console.log(response);
        if (response.status === 201) {
          let type = file.type;
          if (!type.toString().includes('video')) {
              response.data.data['image'] = image
          }
          
          console.log(response.data.data)
          if (!response.data.data.file_folder_id) {
            
            let files = state.files;
            response.data.data['video_stream_urls'] = 2
              files.unshift(response.data.data);
              dispatch({
                type: 'UPDATE_FILES',
                payload: files
              });
          }
          for (var pair of bodyFormData.entries()) {
            if (pair[0] === 'folder_id') {
              let folder = state.selected_folder;
              response.data.data['video_stream_urls'] = 1
              folder.files.unshift(response.data.data);
              dispatch({
                type: 'SET_ACTIVE',
                payload: folder
              })
            } 
          }
          dispatch({
            type: 'LOADING',
            payload: false
          })

          var currentLocation = window.location.pathname;
          const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
          if (folder_id.match(/^[0-9]+$/)) {
            getFolder(folder_id)
            
          } else {
            getFiles()
          }
          chunks = Math.ceil(file.size / chunkSize, chunkSize);
          chunk = 0;
          // offset = chunk * chunkSize;
          
          // var chunks = Math.ceil(file.size/chunkSize,chunkSize);
          // var chunk = 1;
          // var offset = chunk*chunkSize;
          if (chunks > 1) {
            const percentage = (chunk * 100) / chunks
            // dispatch({
            //   type: 'UPLOAD_MESSAGE',
            //   payload: `Uploading ${percentage.toFixed(0)} %`
            // })
            files_uploaded.map((f) => {
              if (f.id === id) {
                f['upload'] = percentage.toFixed(0);
                dispatch({
                  type: 'UPLOAD_FILES',
                  payload: files_uploaded
                })
              }
            } )
            if (chunk === (chunks - 1)) {
              uploadChunck(response.data.data.id, file, 1, id);
            } else {
              uploadChunck(response.data.data.id, file, 0, id);
            }
          } else {
            files_uploaded.map((f) => {
              if (f.id === id) {
                f['upload'] = 100;
                console.log(files_uploaded)
                dispatch({
                  type: 'UPLOAD_FILES',
                  payload: files_uploaded
                })
              }
            } )
          }
          
          // while (chunk <= (chunks - 1)) {
          //     var offset = chunk*chunkSize;
          //   console.log(file.slice(offset, offset + chunkSize));
          //   if (chunk === (chunks - 1)) {
          //     uploadChunck(response.data.data.id, file.slice(offset, offset + chunkSize), 1);
          //   } else {
          //     uploadChunck(response.data.data.id, file.slice(offset, offset + chunkSize), 0);
          //   }
          //     chunk++;
          // }
          
        }
        
      })
      .catch(function (response) {
        console.log(response);
      });
      
    
  }

  async function uploadChunck(id, file, last, file_id) {
    let files_uploaded = state.files_uploaded;
    var offset = chunk * chunkSize;
    var slice = file.slice(offset, offset + chunkSize);

    console.log(chunk)
    var bodyFormData = new FormData();
    bodyFormData.append('chunk',slice);
    bodyFormData.append('is_last_chunk', last);
    axios({
      method: "post",
      url: api_host + `/storage/file/${id}/chunk`,
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Authorization': `Bearer ${api_authToken}`,
        "Content-Type": "multipart/form-data"
        }
    })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          
          dispatch({
            type: 'LOADING',
            payload: false
          })
          
          // while (chunk <= (chunks - 1)) {
          if (chunk <= (chunks - 1)) {
            chunk++;
            const percentage = (chunk * 100) / chunks
            // dispatch({
            //   type: 'UPLOAD_MESSAGE',
            //   payload: `Uploading ${percentage.toFixed(0)} %`
            // })
            files_uploaded.map((f) => {
              if (f.id === file_id) {
                f['upload'] = percentage.toFixed(0);
                console.log(files_uploaded)
                dispatch({
                  type: 'UPLOAD_FILES',
                  payload: files_uploaded
                })
              }
            } )
            if (chunk === (chunks - 1)) {
              uploadChunck(id, file, 1, file_id);
            } else {
              uploadChunck(id, file, 0, file_id);
            }
          } else {
            files_uploaded.map((f) => {
              if (f.id === file_id) {
                f['upload'] = 100;
                console.log(files_uploaded)
                dispatch({
                  type: 'UPLOAD_FILES',
                  payload: files_uploaded
                })
              }
            } )
          }
            
              
          // }
        }
        
      })
      .catch(function (response) {
        console.log(response);
      });
      
    
  }
  
//   async function uploadFiles(files) {
//     console.log(files)
//     var CryptoJS = require("crypto-js");
//     // const reader = new FileReader();
//     // reader.addEventListener('load', function () {
//     //   var wa = CryptoJS.lib.WordArray.create(reader.result);
//     //   // CryptoJS.SHA256(wa).toString()
//     //   sendFile(CryptoJS.SHA256(wa).toString(), files)
//     //   console.log(CryptoJS.SHA256(wa).toString())
//     // }, false);

//     // if (files) {
//     //   reader.readAsDataURL(files)
//     // }

//     let test = files
//     var wa = CryptoJS.lib.WordArray.create(files);
//     // sendFile(CryptoJS.SHA256(wa).toString(), files)
//     try {
//       let res = await axios.post(api_host + '/api/storage/file', {
//         file: files,
//         file_hash: CryptoJS.SHA256(wa).toString(),
//         name: 'name',
//         chunked: 0
//       },{
//           headers: {
//             'Accept': 'application/json',
//             'Access-Control-Allow-Credentials': true,
//             'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
//             'Authorization': `Bearer ${api_authToken}`
//           },
//         }, { withCredentials: true });
//         console.log(res)
//       } catch (error) {
//         console.log(error);
// }
    
//     // var CryptoJS = require("crypto-js");
//     // var file = files;
//     // var blob1 = file.slice(0, 1024);
//     // var fr1 = new FileReader();

    
//     // fr1.addEventListener('load', function () {
//     //   var wa = CryptoJS.lib.WordArray.create(fr1.result);
//     //   CryptoJS.SHA256(wa).toString()
//     //   console.log(blob1);
//     //   sendFile(CryptoJS.SHA256(wa).toString(), blob1)
//     // }, false);
//     // fr1.readAsArrayBuffer(blob1);

    

 

    
//     // const reader = new FileReader();
//     // let id = uuid();
//     // id = generateHash(id);

//     // reader.addEventListener('load', function () {

//     //   const file = {
//     //     id: id,
//     //     name: files.name,
//     //     size: files.size,
//     //     type: files.type.split('/').pop(),
//     //     data: reader.result,
//     //     block_hash: '',
//     //     access: [],
//     //     date: new Date(),
//     //     minted: false,
//     //   }

//     //   dispatch({
//     //     type: 'UPLOAD_FILES',
//     //     payload: file
//     //   })

//     //   dispatch({
//     //     type: 'SET_UPLOADING',
//     //   })

//     //   dispatch({
//     //     type: 'LOADING',
//     //     payload: true
//     //   })
      
//     //   saveToIPFS(id, file)

//     // }, false)

//     // if (files) {
//     //   reader.readAsDataURL(files)
//     // }

//   }


  async function pinFile(dataURL) {
    let key = 'f399564e2f90501c5ff4'.toString();
    let secret = '729e78fbcb48b2304c2942d6741d632a0c4bd357eaf5b4a428fa0f6eb34029e7'.toString();
    try {
      let res = await axios.post(`https://api.pinata.cloud/pinning/pinFileToIPFS`, {
        headers: {
          "Content-Type": "multipart/form-data;",
          "pinata_api_key": "f399564e2f90501c5ff4",
          "pinata_secret_api_key": "729e78fbcb48b2304c2942d6741d632a0c4bd357eaf5b4a428fa0f6eb34029e7",
        },
        data: {
          file: dataURL,
        }
      });
      console.log(res);
      
    } catch (error) {
      console.log(error);
    }

    
    // const fs = require('fs');
    // const readableStreamForFile = fs.createReadStream(dataURL);
    // console.log(readableStreamForFile);

    // const data = dataURL.replace(/^data:image\/png;base64,/, "");
    // const buff = Buffer.from(data, "base64");
    // const stream = Readable.from(buff);

    // // ¡¡ THE HACK !!
    // stream.path = "some_filename.png";

    // const res = await pinata.pinFileToIPFS(stream);

    // return res.IpfsHash;
  }

  /*
  * Call contract
  *
  */
  // async function contractCall(file_id, data) {
  //   let royalties = data.royalties;
  //   // tupleCV({
  //   //           address: standardPrincipalCV(data.royalties[0].address),
  //   //           percentage: uintCV(data.royalties[0].percentage * 100)
  //   //         }),
    
  //   let functionArgs = [
  //       uintCV(file_id),
  //       uintCV(data.price),
  //       trueCV(),
  //       stringAsciiCV(data.name),
  //       someCV(
  //         listCV([
  //           royalties.map((royalty) => {
  //             tupleCV({
  //               address: standardPrincipalCV(royalty.address),
  //               percentage: uintCV(royalty.percentage * 100)
  //             })
  //           })
  //         ]),
  //       ),
  //   ];
  // }

  // let stxPrice = data.price * 1000000;
  //     stxPrice = parseInt(stxPrice);
  //     console.log('aqui:' + stxPrice);
  //     let royals = royalties;
      
  //     let functionArgs;
  //     if (royalties.length === 0 ) {
  //       functionArgs = [
  //         uintCV(file_id),
  //         uintCV(stxPrice),
  //         trueCV(),
  //         stringAsciiCV(data.name),
  //         noneCV(),
  //       ];
  //     } else {
  //       let list = [];
  //       royals.map((royalty, index) => {
  //           list[index] = tupleCV({
  //             address: standardPrincipalCV(royalty.address),
  //             percentage: uintCV(royalty.royalty * 100)
  //           })
  //       })
  //       let newList = listCV(list);
  //       console.log(newList)
  //       functionArgs = [
  //         uintCV(file_id),
  //         uintCV(stxPrice),
  //         trueCV(),
  //         stringAsciiCV(data.name),
  //         someCV(newList),
  //     ];
  //     }

  async function addNFTtoCollection(file_id, estado, attributes, royalties, colec_id, fileData, first_royals) {
    // let collectionID = 200001;
    let data = {};
    let list = [];
    let royaltiesAttr;
    let stxPrice = estado.price;
    stxPrice = parseFloat(stxPrice) * 100;

    let mint_data = {
      id: file_id,
      name: estado.name,
      price: stxPrice,
      royalties: royalties,
      description: estado.description,
      is_public: estado.for_sell ? 1 : 0,
      for_auction: (estado.for_sell && estado.auction) ? 1 : 0,
      for_sale: ((estado.for_sell && estado.auction) || !estado.for_sell) ? 0 : 1,
      auction_duration: estado.auction_duration ? parseInt(estado.auction_duration) * 3600 : 3600,
    };
    let attrs = '';
    if (attributes) {
      attrs = [];
      attributes.forEach((elem) => {
        attrs.push({
          [elem.name]: elem.value
        })
      });
    } 
    
    let metadata_url;
    const bodyIPFS = {
      "name": estado.name,
      "description": estado.description,
      "image": fileData,
      "external_url": '',
      "attributes": attributes,
    }
    console.log(bodyIPFS)

      metadata_url = "";
      let royals = [];
      royalties.map((royalty) => {
        if (royalty.address.length > 0) {
          royals.push({
            'percent': royalty.royalty,
            'address': royalty.address
          })
        }
        
      })
      let royals_first = [];
      console.log(first_royals);
      first_royals.map((royalty) => {
        if (royalty.address.length > 0) {
          royals_first.push({
            'percent': royalty.royalty,
            'address': royalty.address
          })
        }
        
      })
      let tokens = [{
        file_id: file_id,
        description: estado.description,
        name: estado.name,
        price: stxPrice,
        price_currency: 'usd',
        is_public: estado.for_sell ? 1 : 0,
        for_auction: (estado.for_sell && estado.auction) ? 1 : 0,
        for_sale: ((estado.for_sell && estado.auction) || !estado.for_sell) ? 0 : 1,
        auction_duration: estado.auction_duration ? parseInt(estado.auction_duration) * 3600 : 3600,
        royalties: royals,
        attributes: JSON.stringify(attributes),
        first_sale_royalties: royals_first,
        edition_count: parseInt(estado.edition_count),
        explicit_content: estado.explicit_content ? 1 : 0,
      }];
      let token_collection;
      let contract_collection_id;
      state.folders.map((elem) => {
        if (elem.id.toString() === colec_id.toString()) {
          if (elem.non_fungible_token_collection) {
            token_collection = elem.non_fungible_token_collection.id;
            contract_collection_id = elem.non_fungible_token_collection.contract_collection_id;
          }
        }
      })
    mint_to_collection(tokens, token_collection)
    
    // await pinata.pinJSONToIPFS(bodyIPFS).then((result) => {
    //   const hash = result.IpfsHash;
    //   metadata_url = `ipfs://${result.IpfsHash}`;
    //   let royals = [];
    //   royalties.map((royalty) => {
    //     if (royalty.address.length > 0) {
    //       royals.push({
    //         'percent': royalty.royalty,
    //         'address': royalty.address
    //       })
    //     }
        
    //   })
    //   let royals_first = [];
    //   console.log(first_royals);
    //   first_royals.map((royalty) => {
    //     if (royalty.address.length > 0) {
    //       royals_first.push({
    //         'percent': royalty.royalty,
    //         'address': royalty.address
    //       })
    //     }
        
    //   })
    //   let tokens = [{
    //     file_id: file_id,
    //     description: estado.description,
    //     name: estado.name,
    //     metadata_url: metadata_url,
    //     price: stxPrice,
    //     price_currency: 'usd',
    //     is_public: estado.for_sell ? 1 : 0,
    //     for_auction: (estado.for_sell && estado.auction) ? 1 : 0,
    //     for_sale: ((estado.for_sell && estado.auction) || !estado.for_sell) ? 0 : 1,
    //     auction_duration: estado.auction_duration ? parseInt(estado.auction_duration) * 3600 : 3600,
    //     royalties: royals,
    //     attributes: JSON.stringify(attributes),
    //     first_sale_royalties: royals_first,
    //     edition_count: parseInt(estado.edition_count),
    //     explicit_content: estado.explicit_content ? 1 : 0
    //   }];
    //   let token_collection;
    //   let contract_collection_id;
    //   state.folders.map((elem) => {
    //     if (elem.id.toString() === colec_id.toString()) {
    //       if (elem.non_fungible_token_collection) {
    //         token_collection = elem.non_fungible_token_collection.id;
    //         contract_collection_id = elem.non_fungible_token_collection.contract_collection_id;
    //       }
    //     }
    //   })
    //   // if (userSession.isUserSignedIn() && !estado.auction) {
    //   //   contractMintToCollection(tokens, token_collection, contract_collection_id)
    //   // } else {
    //   //   mint_to_collection(tokens, token_collection)
    //   // }
    //   mint_to_collection(tokens, token_collection)
    // }).catch((err) => {
    //     //handle error here
    //     dispatch({
    //       type: 'UPDATE_HASH',
    //       payload: 'Error uploading metadata to IPFS. Please try again.'
    //     });
    //     console.log(err);
    // });

    
    
    // if (userSession.isUserSignedIn()) {
    
    //   data["price"] = uintCV(stxPrice);
    //   data["for-sale"] = trueCV();

    //   const metadata = stringAsciiCV(`https://locker-app.mypinata.cloud/ipfs/${file_id}`);

    //   if (royalties.length > 0) {
    //     royalties.map((royalty, index) => {
    //       console.log(royalty)
    //       console.log(index)
    //       list[index] = tupleCV({
    //         address: standardPrincipalCV(royalty.address),
    //         percentage: uintCV(royalty.royalty * 100),
    //       })
    //     })
    //     let newList = listCV(list);
    //     royaltiesAttr = someCV(newList)
    //   } else {
    //     royaltiesAttr = noneCV();
    //   }

    //   let functionArgs = [
    //     uintCV(estado.collectionID),
    //     tupleCV(data),
    //     metadata,
    //     royaltiesAttr,
    //   ];

    //   openContractCall({
    //     contractAddress: CONTRACT_ADDRESS,
    //     contractName: CONTRACT_NAME,
    //     functionName: 'mint-to-collection',
    //     functionArgs,
    //     network: STACKS_NETWORK,
    //     postConditions: [],
    //     postConditionMode: PostConditionMode.Allow,
    //     appDetails: {
    //       name: 'Locker',
    //       icon: window.location.origin + '/favicon.ico',
    //     },
    //     finished: data => {
    //       console.log('saved');
    //       dispatch({
    //         type: 'UPDATE_HASH',
    //         payload: data.txId
    //       });
    //       console.log({ data });
    //       save_to_collection(file_id, stxPrice, estado, attributes, royalties, data.txId, estado.collectionID);
            
    //     },
    //   });
    // } else {
      
    //   mint_to_collection(tokens, token_collection)
    // }
  }

  async function mint_to_collection(tokens, token_collection) {
    

    const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
    const authToken = localStorage.getItem('auth_token');
    
    try {
      let res = await axios.post(host + `/mint/collection/${token_collection}`,
        {
          tokens: tokens,
          network: 'stacks'
        }, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${authToken}`
        },
      }, { withCredentials: true });
      console.log(res);
      if (res.status === 200) {
        console.log(res.data);
        // let nfts = res.data.data.non_fungible_tokens;
        // let nft_id = nfts[nfts.length - 1].id;
        
        dispatch({
          type: 'UPDATE_HASH',
          payload: res.data.data.id
        });
        
        // save_to_collection(file_id, stxPrice, estado, attributes, royalties, res.data.data.id, colec_id, nft_id)
      }
      
    } catch (error) {
      console.log(error);
      dispatch({
          type: 'UPDATE_HASH',
          payload: 'Error Minting NFT. Please try again.'
        });
      return false;
    }
  }

  async function createCollection(folder_id, estado, attributes) {
    let folders = [...state.folders];
    let files = [];
    let data = {};
    let list = [];
  
    // if (userSession.isUserSignedIn()) {
    //   contractMintCollection(folder_id, attributes, estado)
    // } else {
    //   mint_collection(folder_id, attributes, estado)
    // }
    mint_collection(folder_id, attributes, estado)
  }

  async function contractCall(file_id, data, royalties, fileData, first_royals) {
    console.log(file_id);
    let files = state.files;
    let file_url;
    files.map(elem => {
      if (elem.id.toString() === file_id.toString()) {
        file_url = elem.ipfs_url
      }
    })

    const id = generateHash(file_id);
    dispatch({
      type: 'UPDATE_DATA',
      payload: data
    });

    if (file_id) {
      dispatch({
        type: 'UPDATE_MESSAGE',
        payload: 'Minting NFT'
      });
      
      const hash = 'https://gateway.pinata.cloud/ipfs/' + file_id;

      let stxPrice = data.price;
      stxPrice = parseFloat(stxPrice) * 100;
      
      let royals = [];
      royalties.map((royalty) => {
        royals.push({
          'percent': royalty.royalty,
          'address': royalty.address
        })
      })
      let royals_first = [];
      first_royals.map((royalty) => {
        if (royalty.address.length > 0) {
           royals_first.push({
            'percent': royalty.royalty,
            'address': royalty.address
          })
        }
       
      })
      console.log(stxPrice)

      let mint_data = {
        id: file_id,
        name: data.name,
        price: stxPrice,
        description: data.description,
        royalties: royals,
        is_public: data.for_sell ? 1 : 0,
        for_auction: (data.for_sell && data.auction) ? 1 : 0,
        for_sale: ((data.for_sell && data.auction) || !data.for_sell) ? 0 : 1,
        auction_duration: data.auction_duration ? parseInt(data.auction_duration) * 3600 : 3600,
        royals_first,
        edition_count: parseInt(data.edition_count),
        explicit_content: data.explicit_content ? 1 : 0,
        attributes: [{ name: 'Artist', value: data.artist }],
        metadata_url: "",
        network: "stacks",
        metadata: {external_url: data.url}
      };
      const body = {
        "description": data.description, 
        "external_url": data.url, 
        "image": file_url, 
        "name": data.name,
        "attributes": [{
            "type": "Artist",
            "value": data.artist
          }]
      }
      
      dispatch({
          type: 'UPDATE_IPFS',
          payload: data.name
        });
      mint(mint_data)
      
      // await pinata.pinJSONToIPFS(body).then((result) => {
      //   const hash = result.IpfsHash;
      //   dispatch({
      //     type: 'UPDATE_IPFS',
      //     payload: hash
      //   });
      //   mint_data['metadata_url'] = `ipfs://${result.IpfsHash}`;
      //   mint_data["attributes"] = [{name: 'Artist', value: data.artist}]
      //   mint(mint_data)
          
      // }).catch((err) => {
      //     dispatch({
      //       type: 'UPDATE_HASH',
      //       payload: 'Error uploading metadata to IPFS. Please try again.'
      //     });
      //     console.log(err);
      // });
    }
  }
  async function contractMintToCollection(tokens, token_collection, contract_collection_id) {
    let data = {};
    let functionArgs = [];
    let royals = tokens[0].royalties;
    data["price"] = uintCV(tokens[0].price * 10000);
    if (tokens[0].for_sale === 0) {
      data['for-sale'] = falseCV();
    } else {
      data['for-sale'] = trueCV();
    }
    console.log(contract_collection_id)
    if (tokens[0].royalties.length === 0) {
      functionArgs = [
        uintCV(contract_collection_id),
        listCV([
          tupleCV({
            data: tupleCV(data),
            metadata: stringAsciiCV(tokens[0].metadata_url),
            royalties: noneCV(),
          })
        ]),
      ];
    } else {
      let list = [];
      royals.map((royalty, index) => {
        list[index] = tupleCV({
          address: standardPrincipalCV(royalty.address),
          percentage: uintCV(royalty.percent * 100)
        })
      })
      let newList = listCV(list);
      console.log(newList)
      functionArgs = [
        uintCV(contract_collection_id),
        listCV([
          tupleCV({
            data: tupleCV(data),
            metadata: stringAsciiCV(tokens[0].metadata_url),
            royalties: someCV(newList),
          })
        ]),
      ];
    }
    let userData = userSession.loadUserData();
    const appsMeta = userData.profile.appsMeta;
    const address = (process.env.REACT_APP_STACKS_NETWORK === 'mainnet') ? userData.profile.stxAddress.mainnet : userData.profile.stxAddress.testnet;
    const private_key = userData.appPrivateKey;
    const appUrl = process.env.REACT_APP_LAYER_APP_URL;
    // const appUrl = 'http://localhost:3000';
    const public_key = appsMeta[appUrl].publicKey;

        const p = new Promise((resolve) => sponsorMint(private_key, functionArgs, 'mint-to-collection', resolve));
            p.then(async (stacksTransaction) => {
              const transaction = stacksTransaction.serialize().toString('hex');
              const signature = await signECDSA(private_key, transaction);
              delete tokens[0]['auction_duration'];
              delete tokens[0]['price'];
              delete tokens[0]['price_currency'];
              delete tokens[0]['for_sale'];
              delete tokens[0]['for_auction'];
              const dataComplete = {
                public_key,
                address,
                signature: signature.signature,
                transaction,
                tokens: tokens,
              };
              console.log(dataComplete)
              axios({
                method: "post",
                url: api_host + `/mint/collection/${token_collection}/sponsor`,
                data: dataComplete,
                headers: {
                  'Accept': 'application/json',
                  'Access-Control-Allow-Credentials': true,
                  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                  'Authorization': `Bearer ${api_authToken}`
                }
              })
              .then(async (res) => {
                console.log(res);
                if (res.status === 200) {
                    console.log(res.data);
                    // save_mint(d.id, null, false, file_url, file_metadata, '', d.royalties)
                    dispatch({
                      type: 'UPDATE_HASH',
                      payload: res.data.data.id
                    });
                  }
              })
              .catch((errAuth) => {
                console.log(errAuth);
                dispatch({
                  type: 'UPDATE_HASH',
                  payload: 'Error minting NFT. Please try again.'
                });
              });
            }).catch((e) => console.log(e));
  }

  async function contractMintCollection(folder_id, attributes, estado) {
    let functionArgs = [noneCV()];
    let userData = userSession.loadUserData();
    const appsMeta = userData.profile.appsMeta;
    const address = (process.env.REACT_APP_STACKS_NETWORK === 'mainnet') ? userData.profile.stxAddress.mainnet : userData.profile.stxAddress.testnet;
    const private_key = userData.appPrivateKey;
    const appUrl = process.env.REACT_APP_LAYER_APP_URL;
    // const appUrl = 'http://localhost:3000';
    const public_key = appsMeta[appUrl].publicKey;

        const p = new Promise((resolve) => sponsorMint(private_key, functionArgs, 'mint-collection', resolve));
            p.then(async (stacksTransaction) => {
              const transaction = stacksTransaction.serialize().toString('hex');
              const signature = await signECDSA(private_key, transaction);
              const dataComplete = {
                public_key,
                address,
                signature: signature.signature,
                transaction,
                folder_id,
                name: estado.name,
                description: estado.description,
                tokens: [],
                attributes: JSON.stringify(attributes),
              };
              console.log(dataComplete)
              axios({
                method: "post",
                url: api_host + '/mint/collection/sponsor',
                data: dataComplete,
                headers: {
                  'Accept': 'application/json',
                  'Access-Control-Allow-Credentials': true,
                  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                  'Authorization': `Bearer ${api_authToken}`
                }
              })
              .then(async (res) => {
                console.log(res);
                if (res.status === 201) {
                    console.log(res.data);
                    // save_mint(d.id, null, false, file_url, file_metadata, '', d.royalties)
                    dispatch({
                      type: 'UPDATE_HASH',
                      payload: folder_id
                    });
                  }
              })
              .catch((errAuth) => {
                console.log(errAuth);
              });
            }).catch((e) => console.log(e));
  }
  async function contractMintSingleToken(mint_data) {
    let functionArgs;
    let royals = mint_data.royalties;
    let newData = {
      price: uintCV(mint_data.price * 10000),
    };
    console.log(royals)
    if (mint_data.for_sale === 0) {
      newData['for-sale'] = falseCV();
    } else {
      newData['for-sale'] = trueCV();
    }
    if (mint_data.royalties.length === 0) {
      functionArgs = [
        tupleCV(newData),
        stringAsciiCV(mint_data.metadata_url),
        noneCV(),
      ];
    } else {
      let list = [];
      royals.map((royalty, index) => {
        list[index] = tupleCV({
          address: standardPrincipalCV(royalty.address),
          percentage: uintCV(royalty.percent * 100)
        })
      })
      let newList = listCV(list);
      console.log(newList)
      functionArgs = [
        tupleCV(newData),
        stringAsciiCV(mint_data.metadata_url),
        someCV(newList),
      ];
    }
    
    let userData = userSession.loadUserData();
    const appsMeta = userData.profile.appsMeta;
    const address = (process.env.REACT_APP_STACKS_NETWORK === 'mainnet') ? userData.profile.stxAddress.mainnet : userData.profile.stxAddress.testnet;
    const private_key = userData.appPrivateKey;
    const appUrl = process.env.REACT_APP_LAYER_APP_URL;
    // const appUrl = 'http://localhost:3000';
    const public_key = appsMeta[appUrl].publicKey;

        const p = new Promise((resolve) => sponsorMint(private_key, functionArgs, 'mint-single-token', resolve));
            p.then(async (stacksTransaction) => {
              const transaction = stacksTransaction.serialize().toString('hex');
              const signature = await signECDSA(private_key, transaction);
              const dataComplete = {
                public_key,
                address,
                signature: signature.signature,
                transaction,
                name: mint_data.name,
                metadata_url: mint_data.metadata_url,
                royalties: mint_data.royalties,
                is_public: mint_data.is_public,
                file_id: mint_data.id,
                attributes: JSON.stringify(mint_data.attributes),
                edition_count: mint_data.edition_count
              };
              console.log(dataComplete)
              axios({
                method: "post",
                url: api_host + '/mint/sponsor',
                data: dataComplete,
                headers: {
                  'Accept': 'application/json',
                  'Access-Control-Allow-Credentials': true,
                  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                  'Authorization': `Bearer ${api_authToken}`
                }
              })
              .then(async (res) => {
                console.log(res);
                if (res.status === 201) {
                    console.log(res.data);
                    // save_mint(d.id, null, false, file_url, file_metadata, '', d.royalties)
                    dispatch({
                      type: 'UPDATE_HASH',
                      payload: res.data.data.id
                    });
                  }
              })
              .catch((errAuth) => {
                dispatch({
                  type: 'UPDATE_HASH',
                  payload: 'Error uploading metadata to IPFS. Please try again.'
                });
              });
            }).catch((e) => console.log(e));
  }
  async function mint(d) {
    const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
    const authToken = localStorage.getItem('auth_token');
    
    let royalties = [];
    let first_sale_royalties = []
    console.log(d.royalties)
    console.log(d.royals_first) 
    d.royalties.map((royalty) => {
      royalties.push({
        'percent': royalty.percent,
        'address': royalty.address
      })
    })
    d.royals_first.map((royalty) => {
      first_sale_royalties.push({
        'percent': royalty.percent,
        'address': royalty.address
      })
    })
    try {
      let res = await axios.post(host + '/mint',
        {
          name: d.name,
          price: d.price,
          price_currency: 'usd',
          for_sale: d.for_sale,
          for_auction: d.for_auction,
          auction_duration: d.auction_duration,
          royalties: royalties,
          is_public: d.is_public,
          template: null,
          file_id: d.id,
          first_sale_royalties,
          attributes: JSON.stringify(d.attributes),
          edition_count: d.edition_count,
          explicit_content: d.explicit_content ? 1 : 0,
          network: d.network,
          metadata: d.metadata,
          description: d.description
        }, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${authToken}`
        },
      }, { withCredentials: true });
      console.log(res);
      if (res.status === 201) {
        console.log(res.data);
        // save_mint(d.id, null, false, file_url, file_metadata, '', d.royalties)
        dispatch({
          type: 'UPDATE_HASH',
          payload: res.data.data.id
        });
      }
      
    } catch (error) {
      dispatch({
        type: 'UPDATE_HASH',
        payload: 'Error minting NFT. Please try again.'
      });
      return false;
    }
  }

  async function update_mint(fileId, mint_tx_id, token_id, id, mint_tx_status) {
    console.log(fileId);
    const db = firebase.firestore();
    const nftsRef = db.collection('nfts').doc(fileId.toString());
    nftsRef.update({
      transaction_id: mint_tx_id,
      token_id: token_id,
      backend_nft_id: id,
      transaction_status: mint_tx_status
    })
    .then(function(docRef) {
      console.log('success');
      dispatch({
          type: 'SET_FIREBASE',
          payload: 'success'
      })
    })
    .catch(function(error) {
        dispatch({
          type: 'SET_FIREBASE',
          payload: 'error'
        })
    })
  }

  async function update_collection(colllection_id, mint_tx_id, token_id, id, mint_tx_status) {
    const db = firebase.firestore();
    const nftsRef = db.collection('collections').doc(colllection_id.toString());
    nftsRef.update({
      transaction_id: mint_tx_id,
      token_id: token_id,
      backend_nft_id: id,
      transaction_status: mint_tx_status
    })
    .then(function(docRef) {
      console.log('success');
      dispatch({
          type: 'SET_FIREBASE',
          payload: 'success'
      })
    })
    .catch(function(error) {
        dispatch({
          type: 'SET_FIREBASE',
          payload: 'error'
        })
    })
  }

  async function mint_collection(folder_id, attributes, estado) {
    const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
    const authToken = localStorage.getItem('auth_token');
    
    try {
      let res = await axios.post(host + '/mint/collection',
        {
          folder_id,
          tokens: [],
          attributes: JSON.stringify(attributes),
          description: estado.description
        }, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${authToken}`
        },
      }, { withCredentials: true });
      console.log(res);
      if (res.status === 201) {
        console.log(res.data);
        // save_mint(d.id, null, false, file_url, file_metadata, '', d.royalties)
        dispatch({
          type: 'UPDATE_HASH',
          payload: folder_id
        });
        // save_collection(folder_id, res.data.data.id, attributes, estado)
      }
      
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function cancelNFT() {
    dispatch({
      type: 'UPDATE_MESSAGE',
      payload: 'Saving NFT to IPFS!!'
    });
  }
  /*
  * Set Price - smart contract
  *
  */
  async function contractPrice(file_id, price) {
    const functionArgs = [
      stringAsciiCV(file_id),
      uintCV(price)
      // standardPrincipalCV(state.user.profile.stxAddress.testnet),
    ];
    openContractCall({
      contractAddress: CONTRACT_ADDRESS,
      contractName: CONTRACT_NAME,
      functionName: 'set-price',
      functionArgs,
      senderKey: state.user.profile.stxAddress.testnet,
      // postConditionMode: PostConditionMode.Allow,
      network: STACKS_NETWORK,
      appDetails: {
        name: 'Locker',
        icon: window.location.origin + '/favicon.ico',
      },
      finished: data => {
        console.log('saved');
        console.log({ data });
        settxId(data.txId);
        // updateTransaction(file_id, data.txId);

        dispatch({
          type: 'UPDATE_HASH',
          payload: data.txId
        });
      },
    });
    // return response;
  }

  /*
  * BUY NFT - smart contract
  *
  */
  async function buyNFTContract(file_id) {
    const functionArgs = [
      stringAsciiCV(file_id),
      // standardPrincipalCV(state.user.profile.stxAddress.testnet),
    ];
    openContractCall({
      contractAddress: CONTRACT_ADDRESS,
      contractName: CONTRACT_NAME,
      functionName: 'purchase',
      functionArgs,
      // senderKey: state.user.profile.stxAddress.testnet,
      postConditionMode: PostConditionMode.Allow,
      postConditions: [],
      network: 'testnet_network',
      appDetails: {
        name: 'Locker',
        icon: window.location.origin + '/favicon.ico',
      },
      finished: data => {
        console.log('saved');
        console.log({ data });
        // settxId(data.txId);
        // updateTransaction(file_id, data.txId);

        // dispatch({
        //   type: 'UPDATE_HASH',
        //   payload: data.txId
        // });
      },
    });
    // return response;
  }
  /*
  * Save file to GAIA
  * file/:id
  */
  // async function saveFile(file) {
  //   await storage.putFile('file/' + file.id, JSON.stringify(file), {
  //     encrypt: false
  //   })
  //     .then(() => {
  //       // if (state.active_folder) {
  //       //   updateFolder(file.id)
  //       // } else {
  //       //   updateFiles(file.id);
  //       // }
  //       updateFiles(file.id);
  //       saveHistory(file.id);
  //       dispatch({
  //         type: 'LOADING',
  //         payload: false
  //       })
  //     })
  // }

  /*
  * Update file to GAIA
  * file/:id
  */
  // async function updateFile(id, invite) {
  //   const file = await getFile(id);
  //   file.access.push({
  //     user_email: invite
  //   })
  //   await storage.putFile('file/' + id, JSON.stringify(file), {
  //     encrypt: false
  //   })

  // }

  /*
  * Update file to GAIA - update blockID
  * file/:id
  */
  // async function updateTransaction(id, hash, data, fileData) {
  //   // if(data) { saveToIPFS(id, fileData) }
  //   const file = await getFile(id);
  //   file.minted = true;
  //   file.block_hash = hash;
  //   await storage.putFile('file/' + id, JSON.stringify(file), {
  //     encrypt: false
  //   })
  // }

  async function saveToIPFS(id, file) {
    // setMessage('Saving NFT to IPFS!!')
    let response;

    const body = {
      name: id.toString(),
      file: file.data,
    };
    const options = {
        pinataMetadata: {
            name: id.toString(),
            keyvalues: {
                customKey: 'customValue',
                customKey2: 'customValue2',
            }
        },
        pinataOptions: {
            cidVersion: 0
        }
    };
    await pinata.pinJSONToIPFS(body, options).then((result) => {
        //handle results here
      console.log(result);
      console.log(result.IpfsHash)
      const hash = 'https://locker-app.mypinata.cloud/ipfs/' + result.IpfsHash;
      dispatch({
        type: 'UPDATE_IPFS',
        payload: hash
      });
      
      response = result;
      saveFileToFirebase(result.IpfsHash);
      
    }).catch((err) => {
        //handle error here
      console.log(err);
      response = err;
    });

    return response
  }

  async function saveFileToFirebase(IpfsHash) {
    let files = state.files;
    let folders = state.folders;
    let archives = state.archives;
    let user = state.user.profile ? state.user.profile.stxAddress.testnet : state.user.username;

    var currentLocation = window.location.pathname;
    const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
    console.log(folder_id)
    if (folder_id.match(/^[0-9]+$/)) {
        folders.map((folder) => {
          if (folder.id.toString() === folder_id.toString()) {
            folder.files.unshift({ id: IpfsHash, minted: false })
          }
        })
        dispatch({
          type: 'UPDATE_FOLDERS',
          payload: folders
        })
    } else {
      files.unshift({ id: IpfsHash, minted: false });
      dispatch({
        type: 'UPDATE_FILES',
        payload: files
      });
    }

    const db = firebase.firestore();
    const nftsRef = db.collection('users').doc(user);
    nftsRef.set({
      user_id: state.user.username,
      files: files,
      folders: folders,
      archives: archives,
      video: state.video
    })
    .then(function(docRef) {
      console.log('success');
      dispatch({
          type: 'SET_FIREBASE',
          payload: 'success'
      })
      dispatch({
        type: 'LOADING',
        payload: false
      })
    })
    .catch(function(error) {
        dispatch({
          type: 'SET_FIREBASE',
          payload: 'error'
        })
    })
  }


  /*
  * Get file from GAIA
  * file/:id
  */
  async function getStorageFile(id) {
    let response;
    try {
      let res = await axios.get(api_host + `/storage/file/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      console.log(res)
      if (res.status === 200) {
        response = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
    return response ? response : null;
  }

  async function getFile(id) {
    try {
      const response = await fetch('https://locker-app.mypinata.cloud/ipfs/' + id);
      const data = await response.json();
      console.log(data)
      if (data) {
        return data
      }
    } catch (error) {
      console.log(error)
    }
    

    // try {
    //   const files = await storage.getFile('file/' + id, { decrypt: false });
    //   if (files) {
    //     let response = JSON.parse(files);
    //     return response
    //   }
    // } catch (error) {
    //   console.log(error)
    // }

  }

  /*
  * Delete file from GAIA
  * file/:id
  */
  // async function deleteFile(id) {
  //   let files = state.files;
  //   const result = files.filter(file => file.id !== id)
  //   await storage.putFile('files.json', JSON.stringify(result), {
  //     encrypt: false
  //   })
  //     .then(() => {
  //       dispatch({
  //         type: 'UPDATE_FILES',
  //         payload: result
  //       });
  //       console.log(result)
  //       // storage.putFile('file/' + id, JSON.stringify([]), {
  //       //   encrypt: false
  //       // })
  //     })
  // }

  /*
  * Update files list to GAIA
  * files.json
  */
  async function updateFiles(files) {
    let state_files = state.files;
    files.map(file => {
      state_files.push(file)
    });
    dispatch({
          type: 'UPDATE_FILES',
          payload: state_files
        });
  }

  /*
  * Get files IDs from GAIA
  * files.json
  */
  async function getFiles() {
    let response;
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      try {
      let res = await axios.get(api_host + '/storage', {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      if (res.status === 200) {
        const files = res.data.files.filter(file => file.file_folder_id === null);
        const folders = res.data.folders;
        response = res.data;
        dispatch({
          type: 'UPDATE_FILES',
          payload: files
        });
        dispatch({
          type: 'UPDATE_ALLFILES',
          payload: res.data.files
        })
        dispatch({
          type: 'UPDATE_FOLDERS',
          payload: folders
        })
        dispatch({
          type: 'UPDATE_LINKS',
          payload: res.data.links
        })
        let total = 0;
          if (files.length > 0) {
            files.map(file => {
              if (!file.thumbnail_http_url) {
                total = total + 1;
              }
            })
          }
          
          if (total > 0) {
            // aqui const interval = setInterval(() => {
            //   getFiles()
            // }, 60000);
            // return () => clearInterval(interval);

            // setInterval(() => getFolder(id), 5000);
            
          }
        // files.map(file => {
        //   if (!file.thumbnail_http_url) {
        //     getFiles()
        //   }
        // });
        // var currentLocation = window.location.pathname;
        // const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
        // if (folder_id.match(/^[0-9]+$/)) {
        //   let folder = state.selected_folder;
        //   folders.map(f => {
        //     if (f.id.toString() === folder_id.toString()) {
        //       dispatch({
        //         type: 'SET_ACTIVE',
        //         payload: f
        //       })
        //     }
        //   })
        //   // response.data.data['video_stream_urls'] = 1
        //   // folder.files.unshift(response.data.data);
          
        // }
      }
    } catch (error) {
      localStorage.removeItem('auth_token');
      userSession.signUserOut();
      window.location = '/';
    }
    return response ? response : null
    } else return
    
  }

  async function loadMore() {
    let links = state.links;
    let files = state.files;
    let folders = state.folders;
    let allFiles = state.allFiles;
    try {
      let res = await axios.get(links.next, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      if (res.status === 200) {
        if (res.data.files.length > 0) {
          const new_files = res.data.files.filter(file => file.file_folder_id === null);
          new_files.map(f => {
            files.push(f);
          })
          dispatch({
            type: 'UPDATE_FILES',
            payload: files
          });
          res.data.files.map(f => {
            allFiles.push(f);
          })
          
          dispatch({
            type: 'UPDATE_ALLFILES',
            payload: allFiles
          })
        }
        if (res.data.folders.length > 0) {
          res.data.folders.map(f => {
            folders.push(f)
          })
          
          dispatch({
            type: 'UPDATE_FOLDERS',
            payload: folders
          })
        }
        dispatch({
          type: 'UPDATE_LINKS',
          payload: res.data.links
        })

      }
    } catch (error) {
      console.log(error);
    }
    return true
  }

  /*
  * Create Folder
  * folders.json
  */
  async function addFolder(name) {
    let folders = state.folders;
    let response;
    try {
      let res = await axios.post(api_host + '/storage/folder', { name },{
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      if (res.status === 201) {
        folders.unshift(res.data.data);
        dispatch({
          type: 'UPDATE_FOLDERS',
          payload: folders
        })
        response = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
    return response ? response : null;
  }

  async function updateMint(folder_id, file_id) {
    // add minted = true in firebase
    let user = state.user.profile ? state.user.profile.stxAddress.testnet : state.user.username;
    let folders = [...state.folders];
    let files = [...state.files];
    let archives = state.archives;
    
    // const active_folder = state.active_folder;
    let folder;

    var currentLocation = window.location.pathname;
    const active_folder = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);

    if (folder_id === true) {
      console.log(folder_id);
      console.log(active_folder)
      folders.forEach(elem => {
        if (elem.id.toString() === active_folder.toString()) {
          elem.files.forEach(file => {
            if (file.id.toString() === file_id.toString()) {
              file['minted'] = true;
            }
          })
        }
      })
    } else if (folder_id === false) {
      files.forEach(file => {
        if (file.id === file_id) {
          file['minted'] = true;
        }
      })
    } else if (file_id === false) {
      folders.forEach(elem => {
        if (elem.id === folder_id) {
          elem['minted'] = true;
        }
      })
    }
    // if (folder_id.match(/^[0-9]+$/)) {  
    //   folders.forEach(elem => {
    //     if (elem.id === folder_id) {
    //       elem['minted'] = true;
    //       elem.files.forEach(file => {
    //         if (file.id === file_id) {
    //           file['minted'] = true;
    //         }
            
    //       })
    //     }
    //   })
    // } else if (file_id) {
    //   if (active_folder.match(/^[0-9]+$/)) {
    //     folders.forEach(elem => {
    //       if (elem.id === folder_id) {
    //         elem.files.forEach(file => {
    //           if (file.id === file_id) {
    //             file['minted'] = true;
    //           }
    //         })
    //       }
    //     })
    //   } else {
    //     files.forEach(file => {
    //       if (file.id === file_id) {
    //         file['minted'] = true;
    //       }
    //     })
    //   }
      
    // }

    const db = firebase.firestore();
    const nftsRef = db.collection('users').doc(user);
    nftsRef.set({
      user_id: state.user.username,
      files: files,
      folders: folders,
      archives: archives,
      video: state.video
    })
    .then(function(docRef) {
      console.log('success');
      // dispatch({
      //     type: 'SET_FIREBASE',
      //     payload: 'success'
      // })
      // dispatch({
      //   type: 'LOADING',
      //   payload: false
      // })
    })
    .catch(function(error) {
        dispatch({
          type: 'SET_FIREBASE',
          payload: 'error'
        })
    })

  }

  async function clearTX() {
    dispatch({
      type: 'UPDATE_HASH',
      payload: ''
    });
  }

  /*
  * Get Folders
  * folders.json
  */
  // async function getFolders() {
  //   let response;
  //   try {
  //     await storage.getFile("folders.json", {
  //       decrypt: false
  //     })
  //       .then((data) => {
  //         response = JSON.parse(data);
  //         dispatch({ type: 'UPDATE_FOLDERS', payload: response })
  //       });
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   return response ? response : null;
  // }

  /*
  * Save File History
  * 
  */
  // async function saveHistory(id) {
  //   let history = [{
  //     action: 'file added',
  //     date: new Date()
  //   }]
  //   await storage.putFile("history/" + id, JSON.stringify(history), {
  //     encrypt: false
  //   })
  //     .then(() => {
  //     })
  // }
  /*
  * Get File History
  * 
  */
  // async function getHistory(id) {
  //   let response;
  //   try {
  //     await storage.getFile("history/" + id, {
  //       decrypt: false
  //     })
  //       .then((data) => {
  //         response = JSON.parse(data);
  //       });
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   return response ? response : null;
  // }


  /*
  * Save Profile to GAIA
  * profile.json
  */
  // async function saveProfile(profile) {
  //   await storage.putFile("profile.json", JSON.stringify(profile), {
  //     encrypt: false
  //   })
  //     .then((d) => {
  //       dispatch({
  //         type:
  //           'UPDATE_PROFILE',
  //         payload: profile
  //       })
  //     })
  // }

  /*
  * Get Profile from GAIA
  * profile.json
  */
  // async function getProfile() {
  //   try {
  //     const profile = await storage.getFile('profile.json', { decrypt: false });
  //     if (profile) {
  //       console.log(profile)
  //       const json = JSON.parse(profile);
  //       dispatch({
  //         type: 'UPDATE_PROFILE',
  //         payload: json
  //       })
  //       return json
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  /*
  * Send data access invite
  * /endpoint
  * @params
  * 
  */
  // async function sendInvite(fileId, invite_email, fileName) {
  //   let response;
  //   const invite = {
  //     id: uuid(),
  //     status: 'sent',
  //     file_id: fileId,
  //     owner: state.profile.email,
  //     invite: invite_email,
  //     file_name: fileName
  //   }
  //   const invite_id = await saveInvite(invite);
  //   const username = state.profile.username;
  //   const userid = username;
  //   const email = invite_email;

  //   if (invite_id) {
  //     let res = await axios.post('http://localhost:4444/sendinvite', {
  //       email,
  //       username,
  //       userid,
  //       invite_id
  //     })
  //     response = res
  //     console.log(res)
  //   }

  //   return response


  // }

  // async function saveInvite(invite) {
  //   let invites = state.invites;
  //   invites.push(invite);

  //   await storage.putFile("invites.json", JSON.stringify(invites), {
  //     encrypt: false
  //   })
  //     .then(() => {
  //       dispatch({ type: 'SET_INVITES', payload: invites })
  //       updateFile(invite.file_id, invite.invite)
  //     })

  //   return invite.id
  // }

  // async function getInvites() {
  //   let response;
  //   try {
  //     await storage.getFile("invites.json", {
  //       decrypt: false
  //     })
  //       .then((data) => {
  //         response = JSON.parse(data);
  //         console.log(response)
  //         dispatch({ type: 'SET_INVITES', payload: response })
  //       });
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   return response ? response : null;
  // }

  async function selectFolder(id) {
    dispatch({
      type: 'SET_FOLDER',
      payload: id
    })
  }

  /*
  * Save Folder
  * 
  */
  // async function saveFolder(id, name) {
  //   const folder = {
  //     id: id,
  //     name: name,
  //     files: []
  //   }
  //   await storage.putFile('folder/' + id, JSON.stringify(folder), {
  //     encrypt: false
  //   })
  //     .then(() => {

  //     })
  // }
  /*
  * Get Folder
  *
  */
  async function getFolder(id) {
    let response;
      
      try {
        let res = await axios.get(api_host + `/storage/folder/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Authorization': `Bearer ${api_authToken}`
          },
        }, { withCredentials: true });
        if (res.status === 200) {
          
          response = res.data;
          dispatch({
            type: 'SET_ACTIVE',
            payload: response
          })
          let files = response.files;
          let total = 0;
          if (files.length > 0) {
            files.map(file => {
              if (!file.thumbnail_http_url) {
                total = total + 1;
              }
            })
          }
          
          if (total > 0) {
            // aqui const interval = setInterval(() => {
            //   getFolder(id)
            // }, 60000);
            // return () => clearInterval(interval);

            // setInterval(() => getFolder(id), 5000);
            
          }
        }
      } catch (error) {
        console.log(error);
      }
  
    
    return response ? response : null;
  }

  async function getNFT(id) {
    let response;
    try {
      let res = await axios.get(api_host + `/nft/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      if (res.status === 200) {
        response = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
    return response ? response : null;
  }

  async function getCollection(id) {
    let response;
    try {
      let res = await axios.get(api_host + `/collection/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      console.log(res)
      if (res.status === 200) {
        response = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
    return response ? response : null;
  }


  async function updateFolder(folder_id, files) {
    let folders = state.folders;
    folders.map(folder => {
      if (folder.id.toString() === folder_id.toString()) {
        folder.files = files
      }
    });
    dispatch({
      type: 'UPDATE_FOLDERS',
      payload: folders
    })
  }

  async function save_collection(folder_id, txId, attributes, estado) {
    let username;
    if (state.user.auth) {
      const authToken = localStorage.getItem('auth_token');
      let user = await viewProfile(authToken);
      username = user.username
    } else {
      username = state.user.profile.stxAddress.testnet
    }

    const db = firebase.firestore();
    const nftsRef = db.collection('collections').doc(folder_id.toString());
    nftsRef.set({
      username: username,
      collection: true,
      id: folder_id.toString(),
      file_id: '',
      attributes,
      date: new Date(),
      transaction_id: txId,
      transaction_status: 'pending',
      contract: {
        address: CONTRACT_ADDRESS,
        name: CONTRACT_NAME,
        network: process.env.REACT_APP_STACKS_NETWORK
      },
      name: estado.name,
      description: estado.description
    })
    .then(function(docRef) {
      console.log('success');
      dispatch({
          type: 'SET_FIREBASE',
          payload: 'success'
      })
      updateMint(folder_id, false);
    })
    .catch(function(error) {
        dispatch({
          type: 'SET_FIREBASE',
          payload: 'error'
        })
    })
  }

  async function save_to_collection(file_id, stxPrice, estado, attributes, royalties, txId, colec_id, nft_id) {
    let username;
    let user_wallet = '';
    if (state.user.auth) {
      const authToken = localStorage.getItem('auth_token');
      let user = await viewProfile(authToken);
      username = user.username;
      // save_mint(file_id, null, false, '', estado, '', royalties, nft_id)
    } else {
      username = state.user.profile.stxAddress.testnet
      user_wallet = state.user.profile.stxAddress.testnet
    }

    const db = firebase.firestore();
    const nftsRef = db.collection('nfts').doc(file_id.toString());
    nftsRef.set({
      collection_id: colec_id,
      user_id: username,
      user_wallet,
      file_id: file_id,
      id: file_id,
      metadata: estado,
      date: new Date(),
      transaction_id: txId,
      transaction_status: 'pending',
      ipfs: file_id,
      attributes,
      royalties,
      contract: {
        address: CONTRACT_ADDRESS,
        name: CONTRACT_NAME,
        network: process.env.REACT_APP_STACKS_NETWORK
      },
      backend_nft_id: nft_id ? nft_id : ''
    })
    .then(function(docRef) {
      console.log('success');
      dispatch({
          type: 'SET_FIREBASE',
          payload: 'success'
      })
      updateMint(true, file_id);
      
    })
    .catch(function(error) {
        dispatch({
          type: 'SET_FIREBASE',
          payload: 'error'
        })
    })
  }
  
  async function save_mint(file_id, file_template, file_private, file_url, file_metadata, file_data, royalties, nft_id) {
    // let id = generateHash(file_id);
    let id = file_id.toString();
    let username;
    let user_wallet = '';
    if (state.user.auth) {
      const authToken = localStorage.getItem('auth_token');
      let user = await viewProfile(authToken);
      username = user.username;
    } else {
      username = state.user.profile.stxAddress.testnet
      user_wallet = state.user.profile.stxAddress.testnet
    }
    
    const db = firebase.firestore();
    const nftsRef = db.collection('nfts').doc(id);
    nftsRef.set({
      user_id: username,
      user_wallet: user_wallet,
      file_id: id,
      id,
      data: file_data,
      public: file_private,
      template: file_template,
      url: file_url,
      metadata: file_metadata,
      royalties: royalties,
      date: new Date(),
      transaction_id: txId ? txId : state.tx_hash,
      transaction_status: 'pending',
      ipfs: id,
      contract: {
        address: CONTRACT_ADDRESS,
        name: CONTRACT_NAME,
        network: process.env.REACT_APP_STACKS_NETWORK
      },
      backend_nft_id: nft_id ? nft_id : ''
    })
    .then(function(docRef) {
      console.log('success');
      dispatch({
          type: 'SET_FIREBASE',
          payload: 'success'
      })
      updateMint(false, id);
    })
    .catch(function(error) {
        dispatch({
          type: 'SET_FIREBASE',
          payload: 'error'
        })
    })
  }

  async function resetMint() {
    dispatch({
      type: 'SET_FIREBASE',
      payload: 'loading'
    });
    dispatch({
      type: 'UPDATE_HASH',
      payload: ''
    })
    dispatch({
      type: 'UPDATE_IPFS',
      payload: ''
    })
    dispatch({
      type: 'UPDATE_MESSAGE',
      payload: 'Saving NFT to IPFS!!'
    })
  }

  async function setUploading() {
    dispatch({
      type: 'SET_UPLOADING',
    })
    dispatch({
      type: 'UPLOAD_FILES',
      payload: []
    })
  }

  /*
  * Get NFTs
  *
  */
  async function getNFTS() {
    const snapshot = await firebase.firestore().collection('nfts').get()
    return snapshot.docs.map(doc => {
      console.log(doc);
      doc.data()
    });
  }

  async function getLastMinted() {
    const snapshot = await firebase.firestore().collection('nfts').get()
    return snapshot.docs.map(doc => {
      console.log(doc);
      doc.data()
    });
  }

  async function archiveFile(file_id) {
    let response;
    try {
      let res = await axios.post(api_host + `/storage/file/${file_id}/archive`, {}, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      console.log(res);
      if (res.status === 200) {
        
        // getFiles()
        response = res.data.data;
      }
      
    } catch (error) {
      console.log(error);
      response = false;
    }
    return response;
  }


  async function archiveFolder(folder_id) {
    try {
      let res = await axios.post(api_host + `/storage/folder/${folder_id}/archive`, {}, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      console.log(res);
      if (res.status === 200) {
        getFiles()
      }
      
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  

  // async function updateArchive(fileId) {
  //   let archives = state.archives;
  //   archives.unshift({ id: fileId, folder: '' });

  //   await storage.putFile('archives.json', JSON.stringify(archives), {
  //     encrypt: false
  //   })
  //     .then(() => {
  //       dispatch({
  //         type: 'UPDATE_ARCHIVES',
  //         payload: archives
  //       })
  //     })
  // }

  async function getArchives() {
    let response;
    try {
      let res = await axios.get(api_host + '/storage', {
        archive: 1
      },{
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      if (res.status === 200) {
        
      }
    } catch (error) {
      localStorage.removeItem('auth_token');
      userSession.signUserOut();
      window.location = '/';
    }
    return response ? response : null
    
    
  }

  async function getWallets() {
    let response;
    try {
      let res = await axios.get(api_host + '/me/wallets',{
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      if (res.status === 200) {
        response = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
    return response ? response : null
    
    
  }
  async function setTerms() {
    try {
      let res = await axios.post(api_host + '/me', {
        accepted_terms_of_service: 1
      },{
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      if (res.status === 200) {
        let user = res.data.data;
        console.log(res.data.data);
        setUser(user)
      }
      
    } catch (error) {
      localStorage.removeItem('auth_token');
      userSession.signUserOut();
      window.location = '/';
    }
  }
  
  async function removeBanner() {
    try {
      let res = await axios.post(api_host + '/me', {
        watched_intro_video: 1
      },{
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${api_authToken}`
        },
      }, { withCredentials: true });
      if (res.status === 200) {
        let user = res.data.data;
        console.log(res.data.data);
        setUser(user)
      }
      
    } catch (error) {
      console.log(error);
    }



  }

  async function getNFTOwner(tokenId) {
    console.log('-->' + tokenId);
    const options = {
      contractAddress: CONTRACT_ADDRESS,
      contractName: CONTRACT_NAME,
      functionName: 'get-owner',
      functionArgs: [uintCV(tokenId)],
      network: STACKS_NETWORK,
      senderAddress: CONTRACT_ADDRESS
    };
    // const response = await callReadOnlyFunction(options);
    // console.log(response)
    return callReadOnlyFunction(options).then((CVresult) => {
      console.log(CVresult);
      if (CVresult.value.value) {
        console.log(cvToValue(CVresult.value.value["address"]))
      }
      // const tokenData = {
      //   owner: cvToValue(CVresult.value.data["token-owner"]),
      //   metaData: cvToValue(CVresult.value.data["token-data"]),
      // };
      return CVresult;
    })
  }


  async function transferNFT(tokenId, recipentAddress) {
    const functionArgs = [
      uintCV(tokenId),
      standardPrincipalCV(state.user.profile.stxAddress.testnet),
      standardPrincipalCV(recipentAddress),
    ];
    openContractCall({
      contractAddress: CONTRACT_ADDRESS,
      contractName: CONTRACT_NAME,
      functionName: 'transfer',
      functionArgs,
      network: STACKS_NETWORK,
      postConditionMode: PostConditionMode.Allow,
      postConditions: [],
      finished: data => {
        console.log('saved');
        console.log({ data });
        // historyFile(tokenId)
      },
    });
  }

  // async function historyFile(file_id) {
  //   const file = await getFile(file_id);
  //   console.log(file);
  //   deleteFile(file_id);
  //   updateHistory(file_id);
  // }

  // async function updateHistory(fileId) {
  //   let hitoryFiles = state.history;
  //   hitoryFiles.unshift({ id: fileId, folder: '' });

  //   await storage.putFile('history.json', JSON.stringify(hitoryFiles), {
  //     encrypt: false
  //   })
  //     .then(() => {
  //       dispatch({
  //         type: 'UPDATE_HISTORY',
  //         payload: hitoryFiles
  //       })
  //     })
  // }

  // async function getHistories() {
  //   try {
  //     const files = await storage.getFile('history.json', { decrypt: false });
  //     if (files) {
  //       const json = JSON.parse(files);
  //       dispatch({
  //         type: 'UPDATE_HISTORY',
  //         payload: json
  //       })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  async function authEmail(email) {
    const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
    const appUrl = process.env.REACT_APP_APP_URL;
    const qs = `?email=${email}&auth_url=${appUrl}/test`;
    try {
      let res = await axios.post(host + '/auth/login/magic-link' + qs, {
        headers: {
          'Accept': 'application/json',
        },
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  async function createAccount(d) {
    const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
    const authToken = localStorage.getItem('auth_token');
    console.log(authToken);
    
    try {
      let res = await axios.post(host + '/me',
        {
          username: d.username,
          country: d.country
        }, {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Authorization': `Bearer ${authToken}`
        },
      }, { withCredentials: true });
      console.log(res);
      if (res.status === 200) {
        console.log(res.data);
        // setLoad(false);
        let user = res.data.data;
        user["auth"] = localStorage.getItem('auth_token');
        setUser(user)
        return (res);
      }
      
    } catch (error) {
      console.log(error.response);
      return error.response;
    }
  }

  return (
    <GlobalContext.Provider value={{
      files_uploaded: state.files_uploaded,
      user: state.user,
      files: state.files,
      profile: state.profile,
      invites: state.invites,
      loading: state.loading,
      folders: state.folders,
      tx_hash: state.tx_hash,
      mint_nft: state.mint_nft,
      uploading_files: state.uploading_files,
      archives: state.archives,
      ipfs: state.ipfs,
      message: state.message,
      history: state.history,
      firebase_message: state.firebase_message,
      active_folder: state.active_folder,
      video: state.video,
      allFiles: state.allFiles,
      links: state.links,
      selected_folder: state.selected_folder,
      upload_message: state.upload_message,
      uploadFiles,
      uploadFiles2,
      setUser,
      getFiles,
      getFile,
      // saveProfile,
      // getProfile,
      // sendInvite,
      // getInvites,
      // deleteFile,
      addFolder,
      // getFolders,
      // getHistory,
      selectFolder,
      getFolder,
      contractCall,
      save_mint,
      setUploading,
      contractPrice,
      getNFTS,
      buyNFTContract,
      getLastMinted,
      archiveFile,
      getArchives,
      setTerms,
      // saveFile,
      removeBanner,
      getNFTOwner,
      transferNFT,
      // getHistories,
      authEmail,
      cancelNFT,
      resetMint,
      viewProfile,
      createCollection,
      addNFTtoCollection,
      clearTX,
      createAccount,
      update_mint,
      update_collection,
      getNFT,
      getCollection,
      archiveFolder,
      loadMore,
      updateFiles,
      updateFolder,
      getStorageFile,
      dispatch,
      getWallets
    }}>
      {children}
    </GlobalContext.Provider>
  )
}
