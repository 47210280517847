import React, { useState, useContext } from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";

import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Button,
  Menu as MuiMenu,
  MenuItem,
  Typography
} from "@material-ui/core";
import {
  ArrowDropDown
} from '@material-ui/icons';

import { Menu as MenuIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { userSession } from '../authentication';
import { GlobalContext } from '../context/GlobalState';
import NotificationsDropdown from "./NotificationsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  border: solid 1px #d7dde4;
  border-radius: 8px;
  height: 46px;
  // margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
    color: ${(props) => props.theme.header.search.color};
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${(props) => props.theme.header.search.color};
    }
    :-ms-input-placeholder {
      color: ${(props) => props.theme.header.search.color};
    }
  }
`;

const Menu = styled(MuiMenu)`
  right: 0;
  left: auto;
`;

const MenuButton = styled(Button)`
  background: transparent;
  color: #000000;
  padding: 0;
  width: auto;
  height: 20px;
  min-width: 10px;
  margin-left: 3px;
`;

const ToolbarSearch = styled(Toolbar)`
  min-height: 46px;
`;

const BreakText = styled.div`
  word-break: break-all;
  color: #000;
  right: 5px;
  display: flex;
  align-items: center;
`;
const LetterText = styled(Typography)`
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    flex-shrink: 0;
    background: rgb(188, 49, 83);
    color: rgb(255, 255, 255);
    width: 36px;
    height: 36px;
    font-size: 15px;
    margin-right: 10px;
`;

const UserText = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    flex-shrink: 0;
    background: rgb(188, 49, 83);
    color: rgb(255, 255, 255);
    width: 36px;
    height: 36px;
    font-size: 15px;
    margin-right: 10px;
  }
`;
const AppBarComponent = ({ onDrawerToggle, openProfile }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { user } = useContext(GlobalContext);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    // pages/settings
    history.push("/pages/settings");
  }
  return (
    <React.Fragment>
      <AppBar position="relative" elevation={0}>
        <ToolbarSearch variant="regular">
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            {/* <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder="Find files and folders" />
              </Search>
            </Grid> */}
            <Grid item style={{position: 'absolute', right: '5px'}}>
              <BreakText >
                {(user && user.username && user.username.length > 0) ?
                  <Typography variant="body2">
                      <LetterText>{user.username.charAt(0)}</LetterText>
                      {user.username.split('.')[0]}
                  </Typography>
                  : <Typography variant="body2">Account Information'</Typography>
                }
                
                <MenuButton
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                >
                 <ArrowDropDown />
                </MenuButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={openProfile}>Profile</MenuItem>
                  <MenuItem onClick={() => {
                    userSession.signUserOut();
                    localStorage.removeItem('auth_token');
                    window.location = '/';
                  }}>Logout</MenuItem>
                </Menu>
              </BreakText>
            </Grid>
            {/* <Grid item xs />
            <Grid item>
              <MessagesDropdown />
              <NotificationsDropdown />
              <LanguagesDropdown />
              <UserDropdown />
            </Grid> */}
          </Grid>
        </ToolbarSearch>
      </AppBar>
    </React.Fragment>
  )
}

export default withTheme(AppBarComponent);
