import React, { useState, useContext } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";

import {
  Badge,
  Box as MuiBox,
  Chip,
  Grid,
  Avatar,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  Button as MuiButton,
  LinearProgress,
  styles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle,
  Card,
  CardContent,
  Paper as MuiPaper
} from "@material-ui/core";

import {
  ExpandLess,
  ExpandMore,
  Dashboard,
  Info,
  Lock
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import { sidebarRoutes as routes } from "../routes/index";
import { GlobalContext } from '../context/GlobalState';
// import { ReactComponent as Logo } from "../vendor/icon.svg";
// import { ReactComponent as Logo } from '../vendor/heylayer-storage-logo@3x.png';
import Logo from "../vendor/heylayer-storage-logo@3x.png";
import { ReactComponent as LayerIcon } from '../vendor/layer-iso.svg';
import { ReactComponent as IconCalendar } from '../vendor/icon-calendar.svg';

import { useHistory } from "react-router-dom";
const Box = styled(MuiBox)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)`
  width: 100%;
  border-radius: 7px;
  height: 32px;
`;
const DialogContentText = styled(MuiDialogContentText)`
  a {
    color: inherit;
  }
`;
const Drawer = styled(MuiDrawer)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: ${(props) => props.theme.sidebar.background};
  
  > div {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 10px;
    border-radius: 6px;
    // height: 90%;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  // border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 50px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  color: #000000;
  fill: #000000;
  width: 210px;
  height: auto;
  font-family: inherit;
  text: {
    font-family: inherit;
  }
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
`;
const LogoImg = styled.img`
  color: #000000;
  fill: #000000;
  margin-top: 5px;
  width: 210px;
  height: auto;
  font-family: inherit;
  text: {
    font-family: inherit;
  }
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)}px;
    padding-right: ${(props) => props.theme.spacing(1.375)}px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
    darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body2.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
   padding: ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(4)}px;
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const LockerSpaceIcon = styled(Dashboard)`
  display: flex;
  margin-right: 5px;
`;

const LockerSpaceBar = styled(LinearProgress)`
  height: 3px;
  color: ${(props) => props.theme.sidebar.footer.color};
  margin: 5px 0;
  border-radius: 2px;
`;

const Icon = styled.div`
  width: auto;
  height: 20px;
  margin-right: 20px;
  svg {
    width: auto;
    height: 100%;
  }
`;

const EncryptContainer = styled.div`
  padding: 15px 10px;
  border-radius: 5px;
  background-color: #d7dde4;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
`;

const BrandText = styled(Typography)`
  margin-left: 10px;
  font-weight: bold;
  font-size: 18px;
`;

const DialogIcon = styled.div`
  padding: 10px 25px;
  text-align: left;
  svg {
    width: 40px;
    height: auto;
  }
`;
const WebWallet = styled.div`
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  svg {
    margin-right: 10px;
  }
`;


const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

const SidebarLink = ({ name, to, badge, icon }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Icon >{icon}</Icon>
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

function AlertDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Info onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogIcon>
          {/* <LayerIcon /> */}
        </DialogIcon>
        <DialogContent style={{padding: '0'}}>
          <DialogContentText style={{ textAlign: 'left', color: '#000', marginBottom: '0' }} id="alert-dialog-description">
            <div style={{ padding: '10px 25px' }}>
              <strong>All files on Layer Drive are decentralized</strong><br /><br />
            All files are stored on IPFS (<a href="https://ipfs.io" target="_blank" rel="noreferrer">https://ipfs.io</a>) and NFTs are minted on the Stacks Blockchain (<a href="https://www.stacks.co/" target="_blank" rel="noreferrer">https://www.stacks.co/</a>) using the SIP-009 Standard (<a href="https://github.com/stacksgov/sips/blob/main/sips/sip-009/sip-009-nft-standard.md" target="_blank" rel="noreferrer">https://github.com/stacksgov/sips/blob/main/sips/sip-009/sip-009-nft-standard.md</a>).  <br /><br />
            {/* <strong>All files in Layer Drive are decentralized</strong><br />
            All NFTs are stored on IPFS (<a href="https://ipfs.io" target="_blank" rel="noreferrer">https://ipfs.io</a>) and minted on the Stacks Blockchain (<a href="https://www.stacks.co/" target="_blank" rel="noreferrer">https://www.stacks.co/</a>) using the SIP-009 Standard (<a href="https://github.com/stacksgov/sips/blob/main/sips/sip-009/sip-009-nft-standard.md" target="_blank" rel="noreferrer">https://github.com/stacksgov/sips/blob/main/sips/sip-009/sip-009-nft-standard.md</a>).  <br /><br />
            <strong>NFTs are on the Bitcoin Blockchain</strong><br />
            Stacks enables smart contracts on the Bitcoin blockchain (<a href="https://bitcoin.org/bitcoin.pdf" target="_blank" rel="noreferrer">https://bitcoin.org/bitcoin.pdf</a>). A public link to the blockchain record is provided with every NFT transaction. Source and additional references: <a href="https://docs.stacks.co/" target="_blank" rel="noreferrer">https://docs.stacks.co/</a><br /><br /> */}
            {/* <strong>Minting without a Digital Wallet</strong><br />
            Minting without a wallet is a much-improved user experience and helps bridge onboarding users to web3. In order for this process to work on a technical level, when a user mints without a digital wallet (which is the default), Layer mints on their behalf (using a Layer-owned address). Layer owns NFT until it is claimed by the Artist. Claiming is not required to complete a sale. Layer simply manages the entire transaction on your behalf and pays you once the sale is complete. <br /><br /> */}
            {/* <strong>Reminder:</strong> Layer cannot take ownership of anyone’s tokens unless the token owner transfers ownership of the NFT to admin, such as in the case of USD or Auction enabled sales.<br /> */}
            </div>
            {/* <WebWallet><IconCalendar />SYNC WEB WALLET - Coming in version 0.2</WebWallet> */}
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus>
                Agree
              </Button>
            </DialogActions> */}
      </Dialog>
    </>
  );
}

const Sidebar = ({ classes, staticContext, location, handleOpen, handleOpenVideo, ...rest  }) => {
  const [open, setOpen] = React.useState(false);
  const { dispatch, getFiles, setUploading } = useContext(GlobalContext);
  const history = useHistory();

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const initOpenRoutes = () => {
    console.log('dispatching')
    dispatch({
      type: 'SET_ACTIVE',
      payload: ''
    })
    setUploading();
    getFiles()
    
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });
    

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const openHelp = () => {
    window.open(`https://stonly.com/guide/en/faq-frequently-asked-questions-dZi6ppaOcC/Steps/901344`, '_blank').focus();
  }
  const goHomePage = () => {
    console.log('dispatching')
    dispatch({
      type: 'SET_ACTIVE',
      payload: ''
    })
    setUploading();
    getFiles()
    history.push('/dashboard/default');
  }
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand onClick={goHomePage}>
        <LogoImg src={Logo} alt="Layer Logo" />
      </Brand>
      {/* <Brand  component={NavLink} to="/dashboard/default" button> */}
        {/* <img src={Logo} alt="Layer Locker logo" onMouseDown="return false;" draggable="false" onDragStart="return false;" component={NavLink} to="/dashboard/default" button /> */}
      {/* </Brand> */}
      <Scrollbar>
        <List disablePadding className="sidebar-list">
          <Items >
            {routes.map((category, index) => (
              <React.Fragment key={index}>
                {/* {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null} */}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    {/* <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                    /> */}

                    {/* <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    > */}
                    {category.children.map((route, index) => (
                      <SidebarLink
                        key={index}
                        name={route.name}
                        to={route.path}
                        icon={route.icon}
                        badge={route.badge}
                      />
                    ))}
                    {/* </Collapse> */}
                  </React.Fragment>
                ) : category.icon ? (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    button
                    badge={category.badge}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        {/* <Grid container direction="row" alignItems="center">
          <Grid item>
            <LockerSpaceIcon />
          </Grid>
          <Grid item>
            Locker space remaining
          </Grid>
        </Grid>
        <LockerSpaceBar
          variant="determinate" value={20}
        />
        <SidebarFooterText variant="body2">
          10 GB of 20 GB used
        </SidebarFooterText> */}
        {/* <Button mr={2} style={{marginBottom: '10px'}} variant="outlined" onClick={openHelp}>
          Help
        </Button>
        <Button mr={2} variant="outlined" onClick={handleOpen}>
          Terms & Conditions
        </Button>  */}
        <EncryptContainer onClick={handleClickOpen}>
          <Lock />
          <Typography variant="caption" >
            ENCRYPTION DETAILS
          </Typography>
          <AlertDialog />
        </EncryptContainer> 
      </SidebarFooter>
    </Drawer>
  );
};


export default withRouter(Sidebar);

// {/* <Grid container spacing={2}>
//           <Grid item>
//             <SidebarFooterBadge
//               overlap="circle"
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "right",
//               }}
//               variant="dot"
//             >
//               <Avatar
//                 alt="Lucy Lavender"
//                 src="/static/img/avatars/avatar-1.jpg"
//               />
//             </SidebarFooterBadge>
//           </Grid>
//           <Grid item>
//             <SidebarFooterText variant="body2">Lucy Lavender</SidebarFooterText>
//             <SidebarFooterSubText variant="caption">
//               UX Designer
//             </SidebarFooterSubText>
//           </Grid>
//         </Grid> */}