import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components/macro";
import axios from 'axios';
import {
  Button as MuiButton,
  Fab as MuiFab,
} from "@material-ui/core";

import {
  AddCircle,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { GlobalContext } from '../context/GlobalState';
import { useHistory } from "react-router-dom";
import { ReactComponent as Add } from '../vendor/file-plus.svg';
const jsSHA = require("jssha");
const Button = styled(MuiButton)(spacing);
var chunkSize = 20971520; //1024*1024; // bytes
    var timeout = 10; // millisec
const Fab = styled(MuiFab)(spacing);
let file = {}
var reader = {};
var slice_size = 1000 * 1024;
const AddIconRound = styled(Add)`
  margin-right: 6px;
  height: 18px;
`;
const ButtonFile = styled(MuiButton)`
  width: 160px;
`;

function AddFile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileData, setFileData] = useState();
  const { uploadFiles2, setUploading, active_folder, dispatch, files_uploaded } = useContext(GlobalContext);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const uploadFile = () => {
    const fileInput = document.getElementById('inputAddFile');
    fileInput.click();
  }
  

  function loading(file, callbackProgress, callbackFinal) {
    //var chunkSize  = 1024*1024; // bytes
    var offset     = 0;
    var size=chunkSize;
    var partial;
    var index = 0;

    if(file.size===0){
        callbackFinal();
    }
    while (offset < file.size) {
        partial = file.slice(offset, offset+size);
        var reader = new FileReader;
        reader.size = chunkSize;
        reader.offset = offset;
        reader.index = index;
        reader.onload = function (evt) {
          
            callbackRead(this, file, evt, callbackProgress, callbackFinal);
        };
        reader.readAsArrayBuffer(partial);
        offset += chunkSize;
        index += 1;
    }
}

function callbackRead(obj, file, evt, callbackProgress, callbackFinal){
    if( true ){
        callbackRead_buffered(obj, file, evt, callbackProgress, callbackFinal);
    } else {
        callbackRead_waiting(obj, file, evt, callbackProgress, callbackFinal);
    }
}

var lastOffset = 0;
var chunkReorder = 0;
var chunkTotal = 0;
// time reordering
function callbackRead_waiting(reader, file, evt, callbackProgress, callbackFinal){
    if(lastOffset === reader.offset) {
        console.log("[",reader.size,"]",reader.offset,'->', reader.offset+reader.size,"");
        lastOffset = reader.offset+reader.size;
        callbackProgress(evt.target.result);
        if ( reader.offset + reader.size >= file.size ){
            lastOffset = 0;
            callbackFinal();
        }
        chunkTotal++;
    } else {
        console.log("[",reader.size,"]",reader.offset,'->', reader.offset+reader.size,"wait");
        setTimeout(function () {
            callbackRead_waiting(reader,file,evt, callbackProgress, callbackFinal);
        }, timeout);
        chunkReorder++;
    }
}
// memory reordering
var previous = [];
function callbackRead_buffered(reader, file, evt, callbackProgress, callbackFinal){
    chunkTotal++;

    if(lastOffset !== reader.offset){
        // out of order
        console.log("[",reader.size,"]",reader.offset,'->', reader.offset+reader.size,">>buffer");
        previous.push({ offset: reader.offset, size: reader.size, result: reader.result});
        chunkReorder++;
        return;
    }

    function parseResult(offset, size, result) {
        lastOffset = offset + size;
        callbackProgress(result);
        if (offset + size >= file.size) {
            lastOffset = 0;
            callbackFinal();
        }
    }

    // in order
    console.log("[",reader.size,"]",reader.offset,'->', reader.offset+reader.size,"");
    parseResult(reader.offset, reader.size, reader.result);

    // resolve previous buffered
    var buffered = [{}]
    while (buffered.length > 0) {
        buffered = previous.filter(function (item) {
            return item.offset === lastOffset;
        });
        buffered.forEach(function (item) {
            console.log("[", item.size, "]", item.offset, '->', item.offset + item.size, "<<buffer");
            parseResult(item.offset, item.size, item.result);
            previous.remove(item);
        })
    }

}

Array.prototype.remove = Array.prototype.remove || function(val){
    var i = this.length;
    while(i--){
        if (this[i] === val){
            this.splice(i,1);
        }
    }
};

// Human file size
function humanFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}
  
  const fileInput = () => {
    
    let files = document.querySelector('input[type=file]').files;
    
    if (files.length > 1) {
      dispatch({
        type: 'UPLOAD_MESSAGE',
        payload: 'Getting your files...'
      })
    } else {
      dispatch({
        type: 'UPLOAD_MESSAGE',
        payload: 'Getting your file...'
      })
    }

    for (let i = 0; i < files.length; i++) {
      file = files.item(i);
      send_file(file);
    }
    

    // files.map(file => {
    //   send_file(file);
    // })

    
    
  }

  // const send_file = (file) => {
    
  //   reader = new FileReader();
  //   upload_file( 0 );
  // }

//   function upload_file(start) {
//     const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
//     const authToken = localStorage.getItem('auth_token');
//     var next_slice = start + slice_size + 1;
//     var blob = file.slice( start, next_slice );

//     reader.onloadend = function( event ) {
//         if ( event.target.readyState !== FileReader.DONE ) {
//             return;
//       }
      
//                 var size_done = start + slice_size;
//                 var percent_done = Math.floor( ( size_done / file.size ) * 100 );

//                 if ( next_slice < file.size ) {
//                     // Update upload progress
//                     console.log( `Uploading File -  ${percent_done}%` );

//                     // More to upload, call function recursively
//                     upload_file( next_slice );
//                 } else {
//                     // Update upload progress
//                     console.log( 'Upload Complete!' );
//                 }
              
              
                
                
//     };

//     reader.readAsDataURL( blob );
// }
  
  // const upload_file = (start) => {
  //  const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
  //   const authToken = localStorage.getItem('auth_token');
  //   var next_slice = start + slice_size + 1;
  //   var blob = file.slice( start, next_slice );
  //   var CryptoJS = require("crypto-js");
  //   var SHA256 = CryptoJS.algo.SHA256.create();
    
    
  //   reader.onloadend = function( event ) {
  //     if ( event.target.readyState !== FileReader.DONE ) {
  //           return;
  //     }
  //     var wordBuffer = CryptoJS.lib.WordArray.create(event.target.result);
  //     SHA256.update(wordBuffer);
  //     console.log(wordBuffer)
  //     var encrypted = SHA256.finalize().toString();
      
  //     var bodyFormData = new FormData();
  //     bodyFormData.append('name', file.name);
  //     bodyFormData.append('file', file);
  //     var currentLocation = window.location.pathname;
  //     const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
  //     if (folder_id.match(/^[0-9]+$/)) {
  //       bodyFormData.append('folder_id', folder_id )
  //     }
  //     bodyFormData.append('chunked', 0);
  //     bodyFormData.append('file_hash', encrypted);
                
              
  //     axios({
  //       method: "post",
  //       url: host + '/api/storage/file',
  //       data: bodyFormData,
  //       headers: {
  //         'Accept': 'application/json',
  //         'Access-Control-Allow-Credentials': true,
  //         'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  //         'Authorization': `Bearer ${authToken}`,
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //     .then(function (response) {
  //       console.log(response);
  //       if (response.status === 201) {
  //         var size_done = start + slice_size;
  //               var percent_done = Math.floor( ( size_done / file.size ) * 100 );

  //               if ( next_slice < file.size ) {
  //                   // Update upload progress
  //                   console.log( `Uploading File -  ${percent_done}%` );

  //                   // More to upload, call function recursively
  //                   upload_file( next_slice );
  //               } else {
  //                   // Update upload progress
  //                   console.log( 'Upload Complete!' );
  //               }
  //       }
        
  //     })
  //     .catch(function (response) {
  //       console.log(response);
  //     });
                
  //   };

  //   reader.readAsDataURL( blob );
    
  // }

  const send_file = (file) => {
    setFileData(URL.createObjectURL(file));
    // uploadFiles(file);

    var CryptoJS = require("crypto-js");
    
     
    var SHA256 = CryptoJS.algo.SHA256.create();
    var SHA25 = CryptoJS.algo.SHA256.create();
    var counter = 0;
    var self = this;

    var timeStart = new Date().getTime();
    var timeEnd = 0;
    
    console.log(((counter / file.size) * 100).toFixed(0) + '%');
    let total = Math.ceil(file.size / chunkSize);
    let offset = 0;
    var chunks = Math.ceil(file.size / chunkSize, chunkSize);
    var chunk = 0;
    var off = chunk * chunkSize;
    console.log(total)
   
  
    var reader = new FileReader();
    var hash;
    reader.onload = function (evt) {
      var sha_256 = CryptoJS.algo.SHA256.create();
      sha_256.update(CryptoJS.enc.Latin1.parse(evt.target.result));
      hash = sha_256.finalize();
            // var hash = SHA25.update(CryptoJS.enc.Latin1.parse(evt.target.result));
        // var hash = CryptoJS.SHA256(wordArray);
        // hash = SHA256.finalize().toString();
      console.log(hash.toString())
      var encrypted = hash.toString();
            var currentLocation = window.location.pathname;
            const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
      var bodyFormData = new FormData();
      var fileName = file.name.replace(" ", "");
      fileName = file.name.replace(/["'()]/g, "");
      fileName = fileName.replace(/\.(?=.*\.)/g, '');
            bodyFormData.append('name', file.name.substring(0, file.name.indexOf('.')) );
            bodyFormData.append('file_name', fileName);
            // bodyFormData.append('file', slice);
            if (folder_id.match(/^[0-9]+$/)) {
              bodyFormData.append('folder_id', folder_id )
            }
            if (chunks > 1) {
              bodyFormData.append('chunked', 1);
              bodyFormData.append('chunks', chunks);
            } else {
              bodyFormData.append('chunked', 0);
            }
          
            bodyFormData.append('file_hash', encrypted);
            console.log(encrypted);
            uploadFiles2(bodyFormData, URL.createObjectURL(file), file, chunks);
    };
    reader.readAsBinaryString(file);
    
    
    
    // loading(file,
    //     function (data) {
    //         var wordBuffer = CryptoJS.lib.WordArray.create(data);
    //         SHA256.update(wordBuffer);
    //         counter += data.byteLength;
    //         console.log(wordBuffer)
    //         offset = offset + 1;
    //         //console.log((( counter / file.size)*100).toFixed(0) + '%');
    //     }, function (data) {
    //         //console.log('100%');
    //         let slice = file.slice(off, off + chunkSize);
    //         console.log(slice)
    //       console.log(offset);
    //       console.log(chunks)

    //         var encrypted = SHA256.finalize().toString();
    //         var currentLocation = window.location.pathname;
    //         const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
    //         var bodyFormData = new FormData();
    //         bodyFormData.append('name', file.name.substring(0, file.name.indexOf('.')) );
    //         bodyFormData.append('file_name', file.name);
    //         // bodyFormData.append('file', slice);
    //         if (folder_id.match(/^[0-9]+$/)) {
    //           bodyFormData.append('folder_id', folder_id )
    //         }
    //         if (offset > 1) {
    //           bodyFormData.append('chunked', 1);
    //           bodyFormData.append('chunks', offset);
    //         } else {
    //           bodyFormData.append('chunked', 0);
    //         }
          
    //         bodyFormData.append('file_hash', encrypted);
    //       console.log(encrypted);
    //         uploadFiles2(bodyFormData, URL.createObjectURL(file), file, offset);
    //         // uploadFiles(bodyFormData, URL.createObjectURL(file), file, offset - 1);
          
    //         timeEnd = new Date().getTime();
    //     });
    // loading(file,
    //   function (data) {
    //     total = total - 1;
    //     let chunckFile = file.slice(offset, chunkSize);
    //       var wordBuffer = CryptoJS.lib.WordArray.create(file);
    //       SHA256.update(wordBuffer);
    //       counter += data.byteLength;
    //       var encrypted = SHA256.finalize().toString();
    //         var currentLocation = window.location.pathname;
    //         const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
    //         var bodyFormData = new FormData();
    //         bodyFormData.append('name', file.name);
    //         bodyFormData.append('file', chunckFile);
    //         if (folder_id.match(/^[0-9]+$/)) {
    //           bodyFormData.append('folder_id', folder_id )
    //       }
    //     if (total > 0) {
    //       bodyFormData.append('chunked', 1);
    //     } else {
    //       bodyFormData.append('chunked', 0);
    //     }
          
          
    //       bodyFormData.append('file_hash', encrypted);
    //       console.log((( counter / file.size)*100).toFixed(0) + '%');
          
    //       uploadFiles(bodyFormData, URL.createObjectURL(file), file);
    //         //console.log((( counter / file.size)*100).toFixed(0) + '%');
    //     }, function (data) {
    //         //console.log('100%');
            
    //         timeEnd = new Date().getTime();
    //     });


    dispatch({
      type: 'SET_UPLOAD',
    })
    
    // var currentLocation = window.location.pathname;
    // if (currentLocation.indexOf("folder") === 1) {
    //   const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
    //   history.push(`/folder/${folder_id}`);
    // } else {
    //   history.push(`/dashboard/mylocker`);
    // }
  }

  // const upload_file = useCallback(async (bodyFormData) => {
  //   const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
  //   const authToken = localStorage.getItem('auth_token');
  //   axios({
  //     method: "post",
  //     url: host + '/api/storage/file',
  //     data: bodyFormData,
  //     headers: {
  //       'Accept': 'application/json',
  //       'Access-Control-Allow-Credentials': true,
  //       'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  //       'Authorization': `Bearer ${authToken}`,
  //       "Content-Type": "multipart/form-data"
  //       }
  //   })
  //     .then(function (response) {
  //       console.log(response);
  //     })
  //     .catch(function (response) {
  //       console.log(response);
  //     });
  // }, []);

  return (
    <React.Fragment>
      {/* <form action="" method="post" enctype="multipart/form-data"> */}
        <input name="file" type="file" accept=".jpg,.png,.gif,video/mp4,video/x-m4v,video/*,.wav,.mp3,.aac,.ogg" style={{ display: 'none' }} id="inputAddFile" onChange={fileInput}></input>
        <ButtonFile mr={2} variant="contained" color="primary" onClick={uploadFile}>
          <AddIconRound />
          Upload to IPFS
        </ButtonFile>
      {/* </form> */}
    </React.Fragment>
  );
}

export default AddFile;
