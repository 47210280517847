import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  Home,
  Lock,
  Box,
  Archive,
  Share2
} from "react-feather";
// import { ReactComponent as NFTIcon } from '../vendor/ic-nft.svg';

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/MyLocker"));
const ArchivePage = async(() => import("../pages/dashboards/Archive"))
const SaaS = async(() => import("../pages/dashboards/Apps"));
const FileManager = async(() => import("../pages/dashboards/FileManager"));
const IPFS = async(() => import("../pages/pages/IPFSFiles"));
const History = async(() => import("../pages/pages/History"));

// Pages components
const FileDetails = async(() => import("../pages/pages/FileDetails"));
const Folder = async(() => import("../pages/pages/Folder"));
const Price = async(() => import("../pages/pages/Price"));
const Marketplace = async(() => import("../pages/pages/Marketplace"));
const Settings = async(() => import("../pages/pages/Settings"));
const ClaimNFT = async(() => import("../pages/pages/ClaimNFT"));

// Landing
const Landing = async(() => import("../pages/presentation/Landing"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Home />,
  containsHome: true,
  children: [
    {
      // path: "/dashboard/default",
      path: "/",
      name: "Home",
      icon: <Home />,
      component: Default,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/default",
      name: "Home",
      icon: <Home />,
      component: Default,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/mylocker",
      name: "All Files",
      icon: <Lock />,
      component: Analytics,
      guard: AuthGuard,
    },
    // {
    //   path: "/dashboard/apps",
    //   name: "Apps",
    //   icon: <Box />,
    //   component: SaaS,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/dashboard/price",
    //   name: "Storage",
    //   icon: <Box />,
    //   component: Price,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/dashboard/filemanager",
    //   name: "File Manager",
    //   icon: <Lock />,
    //   component: FileManager,
    //   guard: AuthGuard,
    // },
    {
      path: "/dashboard/archive",
      name: "Archive",
      icon: <Archive />,
      component: ArchivePage,
      guard: AuthGuard
    },
    // {
    //   path: "/dashboard/minted",
    //   name: "Minted",
    //   icon: <Lock />,
    //   component: Marketplace,
    //   guard: AuthGuard
    // },
    // {
    //   path: "/dashboard/IPFS",
    //   name: "IPFS",
    //   icon: <Lock />,
    //   component: IPFS,
    //   guard: AuthGuard
    // },
    // {
    //   path: "/dashboard/history",
    //   name: "History",
    //   icon: <Share2 />,
    //   component: History,
    //   guard: AuthGuard
    // }
  ],
  component: null,

};

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings,
    },
  ],
  component: null,
};

const filesRoutes = {
  id: "Files",
  path: "/files",
  icon: <CreditCard />,
  children: [
    {
      path: "/file/detail/:fileId",
      name: "Details",
      component: FileDetails,
    },
    {
      path: "/folder/:folderId",
      name: "Folder",
      component: Folder,
      guard: AuthGuard,
    },
    {
      path: "/file/detail/:fileId/:folderId",
      name: "File",
      component: FileDetails
    },
    {
      path: "/claim",
      name: "Claim NFT",
      component: ClaimNFT
    }
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/landing-page",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  pagesRoutes,
  filesRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
];
