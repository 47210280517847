import React, { useRef } from 'react';
import { Stage, Layer, Line, Rect } from 'react-konva';
import { animated } from 'react-spring'
import { Spring } from 'react-spring/renderprops'
import * as easings from 'd3-ease';
import styled from "styled-components/macro";

// import '../AnimatedBackground.scss';
const AnimatedWrapper = styled(Stage)`
  position: absolute;
  left: 0;
  z-index: 0;
  background: #4a535c;
  background: linear-gradient(to bottom, #4a535c 0%,#525b63 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a535c', endColorstr='#525b63',GradientType=0 );
  z-index: -1;
`;

const AnimatedBackground = () => {
  const stageEl = useRef(null);
  const rectWidth = 4;
  const rectHeight = 292;
  const defaultW = 1920;
  const defaultH = 1080;
  const lowestY = 795;
  const totalWidth = window.innerWidth;
  const totalHeight = window.innerHeight;
  const scale = totalWidth / defaultW;
  const scaleH = totalHeight / defaultH;
  const defaultGradient = [0, 'rgba(255, 255, 255, 1)', 0.5, 'rgba(255, 255, 255, 0.25)', 1, 'rgba(255, 255, 255, 0)'];
  const reverseGradient = [0, 'rgba(255, 255, 255, 0)', 1, 'rgba(255, 255, 255, 1)'];

  const animatedObjects = [
    {
      isV: true,
      aFrom: {
        x: 373.11,
        y: 434.56,
        scaleY: 0,
        offsetX: 2,
        width: rectWidth,
      },
      aTo: {
        x: 373.11,
        y: -rectHeight,
        scaleY: 1,
      },
    },

    {
      aFrom: {
        offsetY: -1,
        width: rectHeight,
        height: rectWidth,
        x: defaultW,
        y: 432,
      },
      aTo: {
        x: -rectHeight,
        y: 432,
      },
    },

    {
      delay: 511,
      duration: 2300,
      aFrom: {
        offsetY: -1,
        width: rectHeight,
        height: rectWidth,
        x: defaultW,
        y: 432,
      },
      aTo: {
        x: -rectHeight,
        y: 432,
      },
    },

    {
      isV: true,
      duration: 845,
      delay: 2000,
      aFrom: {
        x: 631,
        y: 795,
        scaleY: 0,
        opacity: 1,
        width: rectWidth,
      },
      aTo: {
        opacity: 0,
        x: 631,
        y: 335.06,
        scaleY: 1,
      },
    },

    {
      isV: true,
      duration: 5462,
      reverse: true,
      aFrom: {
        x: 1415,
        y: -rectHeight,
        opacity: 1,
        offsetX: 1,
      },
      aTo: {
        x: 1415,
        y: lowestY - rectHeight / 2,
        opacity: 0,
      },
    },

    {
      aFrom: {
        x: defaultW + rectHeight / 2,
        y: 164,
        width: rectHeight,
        height: rectWidth,
        opacity: 1,
      },
      aTo: {
        x: 1415 - rectHeight / 2,
        y: 164,
        opacity: 0,
      },
    }
  ];

  const bgLines = [
    [373.11, 0, 373.11, 434.56],
    [1416.17, 0, 1416.17, 795],
    [632.66, 435.06, 632.66, 795],
    [0, 435.06, defaultW, 435.06],
    [1416.17, 165.45, defaultW, 165.45],
  ];

  const fitStageIntoParentContainer = () => {
    const totalWidth = window.innerWidth;
    const totalHeight = window.innerHeight;
    const scale = totalWidth / defaultW;
    const scaleH = totalHeight / defaultH;

    stageEl.current.width(window.innerWidth);
    stageEl.current.height(window.innerHeight);
    stageEl.current.scale({ x: scale, y: scaleH });
    stageEl.current.draw();
  };

  const drawWebLines = () => {
    const lines = [];
    const yf = 25.8;

    // Horizontal "Web" lines
    Array(8).fill().map((_, i) => {
      const j = i + 1;
      let yy = ((yf + (j * 0.8)) * j);
      yy += lowestY;

      lines.push(<Line
        key={`w1-${j}`}
        points={[0, yy, defaultW, yy]}
        stroke="rgba(255, 255, 255, 0.5)"
        strokeWidth={0.41}
      />);
    });

    const ll = [-4, -3, -2, -1, 0, 1, 2, 3, 4];
    const xf = defaultW / ll.length;

    ll.map((_, i) => {
      if (_ === 0) return;
      const xs = (defaultW / 2) + (xf * _) - ((_ > 0) ? xf / 2 : -xf / 2);
      const xff = Math.abs(_) > 1
        ? (xf * (_ * 0.57) + (_ * xf * 0.3333))
        : (xf * (_ * 0.57));
      const xe = xs + xff;

      lines.push(<Line
        key={`w2-${_}`}
        points={[xs, lowestY, xe, defaultH]}
        stroke="rgba(255, 255, 255, 0.5)"
        strokeWidth={0.41}
      />);
    });

    lines.push(<Line
      key={`w3-0`}
      points={[0, lowestY, defaultW, lowestY]}
      stroke="rgba(255, 255, 255, 0.5)"
      strokeWidth={0.41}
    />);

    return lines;
  }

  window.addEventListener('resize', () => {
    fitStageIntoParentContainer();
  });

  const weblines = drawWebLines();

  const AnimatedDonut = animated(Rect);

  return (
    <AnimatedWrapper
      className="animated-background"
      ref={stageEl}
      width={window.innerWidth}
      height={window.innerHeight}
      scale={{ x: scale, y: scaleH }}
      
        >
            <Layer>
                {bgLines.map((line, i) => (
                    <Line
                        key={i}
                        points={line}
                        stroke="rgba(255, 255, 255, 0.5)"
                        strokeWidth={1}
                    />
                ))}

                {weblines}

                {animatedObjects.map((ao, z) => (
                    <Spring
                        native
                        key={z}
                        from={ao.aFrom}
                        to={async next => {
                            while (1) {
                                await next({ 
                                    to: ao.aTo,
                                    from: ao.aFrom,
                                    reset: true,
                                    delay: ao.delay || 0,
                                })
                            }
                        }}
                        config={{
                            duration: ao.duration || 3123,
                            easing: easings.easeCubicInOut,
                            delay: ao.delay || 0,
                        }}
                        reset
                    >
                        {(props) => {
                            const gradient = {
                                fillLinearGradientColorStops: ao.reverse ? reverseGradient : defaultGradient,
                                fillLinearGradientStartPoin: ao.isV ? { x: rectWidth / 2, y: 0 } : { x: 0, y: rectWidth / 2 },
                                fillLinearGradientEndPoint: ao.isV ? { x: rectWidth / 2, y: rectHeight } : { x: rectHeight, y: rectWidth / 2 },
                            };

                            return (
                                <AnimatedDonut
                                    width={rectWidth}
                                    height={rectHeight}
                                    cornerRadius={2}
                                    {...props}
                                    {...gradient}
                                />
                            )
                        }}
                    </Spring>
                ))}
            </Layer>
        </AnimatedWrapper>
    );
};

export default AnimatedBackground;
