import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import "./mocks";

import { Provider } from "react-redux";
import store from "./redux/store/index";

const sentryDsn = process.env.REACT_APP_SENTRY_DSN ?? '';
if (sentryDsn && sentryDsn.length > 0) {
  Sentry.init({
    dsn: sentryDsn,
    release: process.env.REACT_APP_SENTRY_RELEASE ?? undefined,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'development',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_RATE) ?? 0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
