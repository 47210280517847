import React from 'react'
import styled from 'styled-components/macro'
import {
  Typography as MuiTypography,
  Chip as MuiChip,
  Avatar,
  Button as MuiButton
} from '@material-ui/core'

import { spacing } from "@material-ui/system";

const Chip = styled(MuiChip)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)`
  width: 100%;
  border: solid 1px #d7dde4;
`;

const ItemContainer = styled.div`
  padding: 8px 5px 5px;
  border-radius: 7px;
  border: solid 1px #d7dde4;
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`;

function FeedCaption({ status }) {
  if (status === 'sent') {
    return (
      <Typography variant="body2" style={{fontWeight: 600}} mb={1}>
        You sent an invitation to:
      </Typography>
    )
  }
  return (
    <Typography variant="body2" style={{fontWeight: 600}} mb={1}>
      You have a new request from:
    </Typography>
  )
  
}

function FeedItem({ invite }) {
  return (
    <>
      <FeedCaption status={ invite.status} />
      <ItemContainer>
        <Chip
          color="secondary"
          avatar={<Avatar>JM</Avatar>}
          label={invite.invite}
        />
        <Typography variant="body2" m={2}>
          <span style={{fontWeight: 600}}>to access file:</span> {invite.file_name}
        </Typography>
        <Button variant="contained" color="secondary">
          Cancel
        </Button>
      </ItemContainer>
    </>
  )
}

export default FeedItem;