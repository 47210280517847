import React from 'react'
import styled from 'styled-components/macro'

import {
  Grid,
  Typography,
  Button as MuiButton
} from "@material-ui/core";

import AddFile from './AddFile';
import FilesUploaded from './FilesUploaded';
import DragArea from './DragArea';

const Button = styled(MuiButton)`
  float: right
`;

function LoadingFiles({ handleDone }) {
  return (
    <>
      <Grid container justify="space-between" alignItems="center" spacing={6} mb={4}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Create NFTs
          </Typography>
          <Typography variant="subtitle1">
            Welcome to your own private and decentralized vault on the internet.
          </Typography>
        </Grid>
        <Grid item>
          <AddFile handleDone={ handleDone } />
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center" spacing={6}>
        {/* <Grid item xs={12}>
          <DragArea />
        </Grid> */}
        <Grid item xs={12}>
          <FilesUploaded />
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" size="large" onClick={() => handleDone()}>
          Done
      </Button>
    </>
  )
}

export default LoadingFiles;
