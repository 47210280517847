import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import styled from "styled-components/macro";
import axios from 'axios';
import Confetti from 'react-confetti'
import SwipeableViews from 'react-swipeable-views';

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
  Divider as MuiDivider,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel as MuiInputLabel,
  OutlinedInput,
  InputAdornment,
  CircularProgress as MuiCircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary,
  Link as MuiLink,
  Breadcrumbs as MuiBreadcrumbs,
  Switch,
  FormControlLabel,
  Input as MuiInput,
  Checkbox,
  RadioGroup as MuiRadioGroup,
  Radio,
  AppBar as MuiAppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import {
  OpenInNew,
  Edit,
  Settings,
  Share,
  Archive,
  Info,
  Lock,
  Icon,
  MailOutline,
  Delete,
  Check,
  ExpandMore as ExpandMoreIcon,
  CheckCircle,
  ArrowForward,
  SelectAll,
  MonetizationOn,
  ArrowForwardIos,
  AddCircleOutline,
  ArrowBack,
  Twitter,
  HighlightOff,
  OpenInBrowser,
  Send,
} from '@material-ui/icons';
import { MoreVertical } from "react-feather";
import { spacing } from "@material-ui/system";
import LoadingLocker from '../components/LoadingLocker';
import { GlobalContext } from '../context/GlobalState';
import Avatar from '../vendor/profile-pic-ufo@2x.png';
import { ReactComponent as AudioIcon } from '../vendor/icon-music-folder.svg';

const useStyles = makeStyles({
  artistPriceContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 0.6fr',
    div: {
      width: '100%'
    },
    label: {
      marginBottom: '6px'
    },
    record: {
      height: '32px',
      padding: '0px 9px',
      borderRadius: '21px',
      border: 'solid 1px #eee',
      backgroundColor: 'rgb(247, 247, 247)',
      display: 'flex',
      textDecoration: 'none',
      color: 'black',
      alignItems: 'center',
      overflow: 'hidden',
      width: '130px'
    },
    record2: {
      width: '180px'
    },
  }
});
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const CardContent = styled(MuiCardContent)`
  position: relative;
  border-radius: 7px;
  border: solid 1px #d7dde4;
  background-color: #f8f9fd;
  overflow: hidden;
  padding: 0;
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;
const Input = styled(MuiInput)`
  border: 1px solid #d7dde4;
  border-radius: 5px;
  padding: 5px 10px;
  &::before,
  &::after {
    content: none;
  }
`;
const AppBar = styled(MuiAppBar)`
  background-color: #ffffff;
  color: #000;
  margin-top: 20px;
  button {
    font-size: 15px;
  }
  .MuiTabs-root {
    min-height: 40px;
    height: 40px;
  }
`;
const InputLabel = styled(MuiInputLabel)`
  color: #000000;
  text-align: left;
  margin-bottom: -5px;
`;

const MenuButton = styled(IconButton)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  
  span {
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flexWrap: wrap;
  padding: 15px 45px 15px 10px;
  justify-content: space-between;
  svg {
    position: absolute;
    right: 50px;
    width: 22px;
    height: auto;
  }
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  svg {
    width: auto;
    height: 60%;
  }
`;

const MenuListItem = styled(MenuItem)`
  padding-left: 10px;
  padding-right: 10px;

  svg {
    height: 16px;
    width: auto;
    color: rgba(0, 0, 0, 0.54);
    margin-right: 10px;
  }
`;

const DialogIcon = styled.div`
  padding: 20px 10px 0;
  text-align: center;
  svg {
    width: 40px;
    height: auto;
  }
`;

const ItemList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DialogContainer = styled(DialogContent)`
  max-width: 850px;
  display: grid;
  grid-template-columns: 280px 1px auto;
  grid-gap: 15px;
`;
const ImagePreview = styled.div`
  div {
    background-image: url('./vendor/bg@2x.png');
    padding: 4px;
    padding-bottom: 0;
    border-radius: 5px;
    height: fit-content;
    overflow: hidden;
    border: dashed 1px rgba(0,0,0,0.18);
    img {
      width: 100%;
      border-radius: 3px;
    }
  }
  p {
    font-weight: bold;
    margin-top: 5px;
  }
`;
const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
`;

const DialogContainerText = styled(DialogContentText)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  min-width: 475px;
  max-width: 100%;
`;
const MetadataForm = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  margin: 20px 0;
`;
const Royalty = styled.div`
    display: grid;
    grid-template-columns: 1fr 100px 15px auto;
    grid-gap: 10px;
    align-items: flex-start;
    .MuiInputBase-root {
      width: 100%;
    }
    svg {
      margin-top: 16px;
      width: 16px;
      cursor: pointer;
      margin-left: -4px;
      &:hover {
        color: black;
      }
    }
`;
const ButtonContinue = styled(Button)`
  width: 100%;
`;
const ButtonContinue2 = styled(Button)`
  width: 100%;
  border: solid 1px #d7dde4;
  margin-top: 20px;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`;
const PercentageText = styled(MuiTypography)`
    font-size: 18px;
    color: #000;
    margin-top: 17px;
    align-self: center;
`;
const ButtonCollaborator = styled(Button)`
  width: 130px;
  background-color: rgba(0,0,0,.04);
`;
const Auction = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    p {
      margin-right: 15px;
    }
`;
const GoBack = styled(Button)`
  position: absolute;
  top: 12px;
  left: 324px;
  min-width: 20px;
`;
const RadioGroup = styled(MuiRadioGroup)`
    flex-direciton: row !important;
`;
const TypoLabel = styled(MuiTypography)`
    color: #000;
    float: left;
    display: inline-flex;
    margin-bottom: -7px;
`;
const TypoLabel2 = styled(MuiTypography)`
    z-index: 200;
`;
const IconInfo = styled(MuiTypography)`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #000;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
    font-size: 10px;
`;
const RoyaltyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 73px;
  grid-gap: 10px;
  margin-bottom: 10px;
  &:hover ~ div {
    display: block;
  }
`;
const Error = styled.p`
  margin: 0;
  color: red;
`;
const LinkBlock = styled(MuiLink)`
  color: #1588d0;
  margin-top: 20px;
`;
const InfoView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.54);
  .MuiInput-root {
    width: 100%;
    margin-top: 7px;
  }
  textarea {
    width: 100%;
    margin-top: 7px;
  }
  div.info_details {
    position: absolute;
    right: -250px;
    width: 250px;
    background: #fff;
    border-radius: 5px;
    z-index: 20;
    text-align: left;
    padding: 5px 10px;
    border: solid 1px rgba(0,0,0,.1);
    display: none;
    color: rgba(0, 0, 0, 0.54);
    &:hover {
      display: block;
    }
  }
  p {
    position: relative;
    &:hover + div {
      display: block;
    }
  }
  .info_icon:hover + .info_details {
    display: block;
  }
`;
const CheckboxRights = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-bottom: -1px;
    padding-left: 5px;
  }
`;
const AudioContainer = styled.div`
  width: 100%;
    height: 200px !important;
    background-image: linear-gradient(rgb(58, 0, 255), rgb(0, 126, 255)) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: blue;
    margin-bottom: 4px !important;
`

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const MintToCollection = ({ file_id, handleLoad, colec_id, fileData, holder, thumbnail, file }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    checkedB: false,
    name: '',
    description: '',
    price: '',
    collectionID: '',
    auction: false,
    auction_duration: 24,
    for_sell: false,
    publish_rights: false,
    explicit_content: false,
    edition_count: 1
  });
  const [disabled, setDisabled] = useState(true);
  const [royalties, setRoyalties] = useState([{address: '', royalty: ''}]);
  const [firstRoyalties, setFirstRoyalties] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [royaltiesScreen, setRoyaltiesScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usdStx, setUsdStx] = useState();
  const { addNFTtoCollection, tx_hash, getCollection, clearTX, folders } = useContext(GlobalContext)
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    if (state.name.length > 0 && state.description.length > 0 && state.publish_rights) {
      setDisabled(false);
    }
  }, [state.name, state.description]);

  const stopPropagationForTab = (event) => {
    if (event.key === "Tab" || event.keyCode === 79 || event.keyCode === 77 || event.keyCode === 65) {
      event.stopPropagation();
    }
  };

  const handleClickOpen = useCallback(async () => {
    setOpen(true);
  }, [tx_hash, open]);

  const handleFirstRoyalty = (event, index) => {
    let elem = event.target;
    let parent = elem.parentElement;
    parent = parent.parentElement.parentElement;
    let env = process.env.REACT_APP_STACKS_NETWORK === 'testnet' ? 'testnet' : 'mainnet';
    let error_label = parent.querySelector('.error_label');
    let error_label2 = parent.querySelector('.error_label2');

    if (event.target.name.includes('address')) {
      error_label.innerHTML = "";
      let newArr = [...firstRoyalties]; 
      newArr[index]['address'] = event.target.value;

      setFirstRoyalties(newArr);
      if ((env === 'testnet' && event.target.value.toString().substring(0, 2) !== "ST") || (env === 'mainnet' && event.target.value.toString().substring(0, 2) !== "SP")) {
        // parent.classList.add('required')
        error_label.innerHTML = "STX Address invalid.<br />"
          // errorMessage.innerHTML += ""
      }  

        if (!event.target.value.toString().match(/^[0-9a-zA-Z]+$/)) {
          // parent.classList.add('required')
          error_label.innerHTML = "STX Address invalid.<br />"
        } 
 
    } else if (event.target.name.includes('percentage')) {
      error_label2.innerHTML = ""
      if (event.target.value.includes('.')) {
        if (event.target.value.split('.')[0].length <= 2) {
          let newArr = [...firstRoyalties]; 
          newArr[index]['royalty'] = event.target.value;

          setFirstRoyalties(newArr);
        }
      } else {
        if (event.target.value.length <= 2) {
          let newArr = [...firstRoyalties]; 
          newArr[index]['royalty'] = event.target.value;

          setFirstRoyalties(newArr);
        }
      }
      let totalFirst = 0;
      firstRoyalties.map((royalty) => {
        totalFirst = totalFirst + parseInt(royalty.royalty);
      })
      if (totalFirst > 90) {
        error_label2.innerHTML = "Percentage total of First Split can't exceed 90%.<br />"
      }
    }
  }

  const handleNameChange = (event, index) => {
    let elem = event.target;
    let parent = elem.parentElement;
    parent = parent.parentElement.parentElement;
    let env = process.env.REACT_APP_STACKS_NETWORK === 'testnet' ? 'testnet' : 'mainnet';
    let error_label = parent.querySelector('.error_label');
    let error_label2 = parent.querySelector('.error_label2');

    if(event.target.name === 'price') {
      let numb = event.target.value;
      if (numb.includes('.')) {
        if (numb.split('.')[1].length <= 2) {
          setState({ ...state, [event.target.name]: event.target.value })
        }
      } else {
        setState({ ...state, [event.target.name]: event.target.value })
      }
    } else if (event.target.name.includes('address')) {
      error_label.innerHTML = "";
      let newArr = [...royalties]; 
      newArr[index]['address'] = event.target.value;

      setRoyalties(newArr);

      if ((env === 'testnet' && event.target.value.toString().substring(0, 2) !== "ST") || (env === 'mainnet' && event.target.value.toString().substring(0, 2) !== "SP")) {
        // parent.classList.add('required')
        error_label.innerHTML = "STX Address invalid.<br />"
          // errorMessage.innerHTML += ""
      }  

        if (!event.target.value.toString().match(/^[0-9a-zA-Z]+$/)) {
          // parent.classList.add('required')
          error_label.innerHTML = "STX Address invalid.<br />"
      }  
    } else if (event.target.name.includes('percentage')) {
      error_label2.innerHTML = "";
      if (event.target.value.includes('.')) {
        if (event.target.value.split('.')[0].length <= 2) {
          let newArr = [...royalties]; 
          newArr[index]['royalty'] = event.target.value;

          setRoyalties(newArr);
        }
      } else {
        if (event.target.value.length <= 2) {
          let newArr = [...royalties]; 
          newArr[index]['royalty'] = event.target.value;

          setRoyalties(newArr);
        }
      }
      let totalSecond = 0;
      royalties.map((royalty) => {
        totalSecond = totalSecond + parseInt(royalty.royalty);
      })
      if (totalSecond > 10) {
        error_label2.innerHTML = "Percentage total of secondary Split can't exceed 10%.<br />"
      }
    } else if (event.target.name.includes('edition_count')) { 
        if (event.target.value.match(/^[0-9]+$/) && event.target.value > 0 && event.target.value !== '-') {
          console.log('mathc number');
          setState({ ...state, [event.target.name]: event.target.value })
        }
    } else if (event.target.name.includes('attrname')) {
      let newArr = [...attributes]; 
      newArr[index]['name'] = event.target.value;
      // newArr[index] = event.target.value;
      setAttributes(newArr);
    } else if (event.target.name.includes('attrvalue')) {
      let newArr = [...attributes]; 
      newArr[index]['value'] = event.target.value;
      // newArr[index] = event.target.value;
      setAttributes(newArr);
    } else {
      const input_value = event.target.value;
      const input_value2 = input_value.charAt(0).toUpperCase() + input_value.slice(1);
      setState({ ...state, [event.target.name]: input_value2 });
    }
    
  }

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    handleLoad(false);
    clearTX()
  }

  const mintCollection = useCallback(async (file_id, state, attributes, royalties, colec_id, fileData) => {
    
    let errorMessage = document.getElementById('error_message');
    errorMessage.innerHTML = "";
    const inputs = document.querySelectorAll('.form_input.metadata_input');
    let verification = 0;
    inputs.forEach(function (elem, index) {
      const input = elem.querySelector('input');
      const val = input.value;
      if (!val && input.name !== 'url') {
        console.log(elem)
        elem.classList.add('required');
        verification = verification + 1;
      }
      
    });
    let royals = [];
    let first_royals = [];
    let totalFirst = 0;
    let totalSecond = 0;
    let STXValid = true;
    let env = process.env.REACT_APP_STACKS_NETWORK === 'testnet' ? 'testnet' : 'mainnet';
    royalties.map((royalty) => {
      totalFirst = totalFirst + parseInt(royalty.royalty);
      if (royalty.address && royalty.royalty) {
        first_royals.push({ address: royalty.address, royalty: royalty.royalty });
      }
    })
    royalties.map((royalty, index) => {
      if (royalty.address.length > 0) {
        if (royalty.address && royalty.royalty) {
          royals.push({ address: royalty.address, royalty: royalty.royalty });
        }
        totalSecond = totalSecond + parseInt(royalty.royalty);
        console.log(process.env.REACT_APP_STACKS_NETWORK === 'testnet' ? 'testnel' : 'mainet')
        if ((env === 'testnet' && royalty.address.toString().substring(0, 2) !== "ST") || (env === 'mainnet' && royalty.address.toString().substring(0, 2) !== "SP")) {
          // const input = document.querySelector('.address_input_' + index);
          // input.classList.add('required');
          verification = verification + 1;
          console.log('begining')
          console.log(royalty.address.toString().substring(0, 2))
          errorMessage.innerHTML += "- STX Address invalid.<br />"
        }

        if (!royalty.address.toString().match(/^[0-9a-zA-Z]+$/)) {
          const input = document.querySelector('.address_input_' + index);
          input.classList.add('required');
          verification = verification + 1;
          console.log('letter numbers')
          STXValid = false;
        }
        
        // if (royalty.address.length !== 41) {
        //   const input = document.querySelector('.address_input_' + index);
        //   input.classList.add('required');
        //   verification = verification + 1;
        //   STXValid = false;
        // }
      }
    })
    // SP or ST
    // console.log(royalsTotal);
    if (totalFirst > 90) {
      const allPercentages = document.querySelectorAll('.percentage_input');
      allPercentages.forEach(function (elem, index) {
        elem.classList.add('required');
        
      });
      verification = verification + 1;
      errorMessage.innerHTML += "- Percentage total of First Split can't exceed 90%.<br />"
    }
    if (totalSecond > 90) {
      const allPercentages = document.querySelectorAll('.percentage_input');
      allPercentages.forEach(function (elem, index) {
        elem.classList.add('required');
        
      });
      verification = verification + 1;
      errorMessage.innerHTML += "- Percentage total of Secondary Split can't exceed 90%.<br />"
    }

    if (!STXValid) {
      errorMessage.innerHTML += "- STX Address invalid.<br />"
    }
    
    if (verification === 0) {
      setLoading(true);
      addNFTtoCollection(file_id, state, attributes, royalties, colec_id, fileData, firstRoyalties)
    }
    
    // setOpen(true);
  }, [addNFTtoCollection, firstRoyalties]);

  

  const addCollaborator = () => {
    setRoyalties(prevState => [...prevState, { address: '', royalty: '' }])
  }

  const addFirstCollaborator = () => {
    setFirstRoyalties(prevState => [...prevState, { address: '', royalty: '' }])
  }

  const removeFirstRoyalty = (e, index) => {
    let royaltiesArray = [...firstRoyalties];
    royaltiesArray.splice(index, 1);
    setFirstRoyalties(royaltiesArray);
  }

  const removeRoyalty = (e, index) => {
    let royaltiesArray = [...royalties];
    royaltiesArray.splice(index, 1);
    setRoyalties(royaltiesArray);
  }

  const addAttribute = () => {
    setAttributes(prevState => [...prevState, { name: '', value: '' }])
  }

  const removeAttribute = (e, index) => {
    let attributesArray = [...attributes];
    attributesArray.splice(index, 1);
    setAttributes(attributesArray);
  }

  const getCurrencies = useCallback(async () => {
    
    const host = process.env.REACT_APP_LAYER_BACKEND_HOST;
    try {
      let res = await axios.get(host + '/coin/STX/price', {
        headers: {
          'Accept': 'application/json',
        },
      });
      
      console.log(res.data.price);
      setUsdStx(res.data.price);
    
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchFolder = useCallback(async () => {
    var currentLocation = window.location.pathname;
    const folder_id = currentLocation.slice(currentLocation.lastIndexOf('/') + 1);
    // console.log(folder_id);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const folder = urlParams.get('folder');
    let array = [];
    setAttributes('');

    if (folder) {
      folders.map((elem) => {
        if (elem.id.toString() === folder.toString()) {
          console.log(elem)
          if (elem.non_fungible_token_collection) {
            console.log(elem.non_fungible_token_collection.attributes)
            array = JSON.parse(elem.non_fungible_token_collection.attributes);
            array.forEach(e => {
              setAttributes(prevState => [...prevState, { name: e, value: ''}])
            })
          }
        }
      })
    } else {
      folders.map((elem) => {
        if (elem.id.toString() === folder_id.toString()) {
          console.log(elem)
          if (elem.non_fungible_token_collection) {
            console.log(elem.non_fungible_token_collection.attributes)
            array = JSON.parse(elem.non_fungible_token_collection.attributes);
            array.forEach(e => {
              setAttributes(prevState => [...prevState, { name: e, value: ''}])
            })
          }
        }
      })
    }
    
  }, [folders]);

  useEffect(() => {
    void Promise.all([getCurrencies(), fetchFolder()]);
  }, [getCurrencies, fetchFolder, tx_hash]);

  const handleAuction = (event) => {
    setState({...state, auction: event.target.checked})
  }

   const handleSwitch = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSell = (event) => {
    setState({...state, for_sell: event.target.checked})
  }

  const selectAuctionDuration = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }
  const handleCheckRights = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState({ ...state, [name]: value });
  }

  return (
    <>
      <ItemList className="mint_to_button" onClick={handleClickOpen}>
        <SelectAll />
        <Typography>Mint to Collection</Typography>
      </ItemList>
      <Dialog
        open={open}
        // onClose={handleClose}
        onKeyDown={stopPropagationForTab}
        aria-labelledby="alert-dialog-title2"
        aria-describedby="alert-dialog-description2"
      >
        {!loading ?
          !royaltiesScreen ?
            <>
              <DialogContainer>
                <ImagePreview>
                  <div>
                    {file && file.file_type && file.file_type === 3 ?
                      <AudioContainer>
                        <AudioIcon />
                      </AudioContainer>
                      :
                      <img src={`https://locker-app.mypinata.cloud/${fileData.replace("ipfs://", "ipfs/")}`} alt={`NFT Layer`} />}
                    </div>  
                    <Typography variant="body2">NFT Preview</Typography>
          </ImagePreview>
            
            <Separator />
            <DialogContainerText id="alert-dialog-description2">
              <Typography variant="h4" color="primary">
                Mint NFT to Collection
              </Typography>
              <GoBack color="primary" onClick={handleClose}>
                <ArrowBack />
              </GoBack>
            <MetadataForm >
              <InfoView>
                    <TypoLabel variant="body2" htmlFor="collection-name">NFT Title  <IconInfo className="info_icon">i</IconInfo>
                    <div className="info_details">
                      <Typography variant="body2">
                        Name of NFT displayed on marketplace.
                      </Typography>
                          </div>
                    </TypoLabel>       
                      <Input id="collection-name" onChange={handleNameChange} label="Collection Name" name="name" className='form_input metadata_input' value={state.name} placeholder="Mona Lisa" />
                    </InfoView>
                    {/* <InputLabel htmlFor="nft-price">Price (USD)</InputLabel> */}
                    <InfoView>
                        <TypoLabel variant="body2" htmlFor="nft-price">Price (USD)  <IconInfo className="info_icon">i</IconInfo>
                        <div className="info_details" style={{width: '190px', right: '-190px'}}>
                          <Typography variant="body2">
                            The price defaults to American Dollars (USD). We keep this price consistent while adjusting the price of STX accordingly. 
                          </Typography>
                              </div>
                        </TypoLabel>
                      <Input style={{ width: '100%' }} type="number" id="nft-price" onChange={handleNameChange} label="Price" name="price" className='form_input metadata_input' placeholder="250.00" value={state.price} />
                    </InfoView>
                    <InfoView>
                        <TypoLabel variant="body2" htmlFor="nft-editions">Editions  <IconInfo className="info_icon">i</IconInfo>
                        <div className="info_details" >
                          <Typography variant="body2">
                            Editions allow you to have a set amount of identical NFTs. You can set the number of Editions here and it will mint the number of Editions you enter. Each one will be priced at the price set above. 
                          </Typography>
                              </div>
                      </TypoLabel>
                      <Input type="number" id="nft-editions" onChange={handleNameChange} label="Editions" name="edition_count" className='form_input metadata_input' value={state.edition_count} placeholder="1" />
                    </InfoView>
                
                    <InfoView>
                        <TypoLabel variant="body2" htmlFor="collection-description">Description  <IconInfo className="info_icon">i</IconInfo>
                        <div className="info_details" >
                          <Typography variant="body2">
                            The description allows collectors and NFT buyers to learn about your NFT. Provide as much information as possible. Buyers love to hear about the creator's journey. The better your description, the more likely your NFT will be purchased.  
                          </Typography>
                              </div>
                        </TypoLabel>
                {/* <InputLabel htmlFor="collection-description">Description</InputLabel> */}
                <textarea style={{ height: '70px', borderRadius: '5px', padding: '5px 10px', fontFamily: 'DM Sans,sans-serif', borderColor: '#d7dde4', outline: 'none'}} id="collection-description" onChange={handleNameChange} value={state.description} label="collection-description" name="description" className='form_input' placeholder="The Mona Lisa is a half-length portrait painting by Italian artist Leonardo da Vinci." />
                </InfoView>
                {attributes.length > 0 && <InputLabel>Attributes</InputLabel>}
                <Royalty style={{gridTemplateColumns: '1fr 1fr'}}>
                  {attributes && attributes.map((attribute, index) => (
                    <div>
                      <InputLabel style={{ marginBottom: '5px' }} htmlFor={`nft-attrvalue${index}`}>{ attributes[index].name }</InputLabel>
                      <Input className="value_input" value={attributes[index].value} id={`nft-attrvalue${index}`} onChange={(e) => handleNameChange(e, index)} label="Value" name={`attrvalue${index}`} />
                    </div>
                    
                  ))}
                </Royalty>
                <InfoView>
                        <TypoLabel variant="body2" style={{fontSize: '15px', fontWeight: 'bold'}}>Royalties  <IconInfo className="info_icon">i</IconInfo>
                        <div className="info_details" style={{width: '320px', right: '-320px'}} >
                          <Typography variant="body2">
                            Royalties allow you to earn a percentage of every sale after the initial NFT is sold. For example, after the initial buyer becomes the owner of your NFT, they may want to sell it to someone else. If that ever happens, a percentage of the sale (that you set here) will automatically appear in your STX wallet. This happens every time the NFT changes hands from one owner to another. A reasonable royalty is anywhere from 2.5 to 10%. We set the default at 5%. You can include as many NFT collaborators as you need so that everyone in the project gets compensated. This way everyone can truly benefit from the creator economy, possible by Blockchain and Layer.
                          </Typography>
                              </div>
                        </TypoLabel>
                      </InfoView>
                    <Error id="error_message"></Error>
                    {firstRoyalties.length > 0 &&
                      <div>
                        <Typography variant="body2" className={classes.sub_title} style={{gridColumn: '1 / -1', marginBottom: '5px', textAlign: 'left', color: '#000', fontWeight: 'bold'}}>
                          First Split
                          </Typography>
                          <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 200px)'}}>
                          {firstRoyalties.map((royalty) => {
                            if (royalty.address.length > 0) {
                              return(
                              <div style={{ position: 'relative', marginBottom: '5px' }}>
                                <RoyaltyContainer style={{ display: 'grid', marginBottom: '0px', gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 89px))', gridGap: '5px' }}>
                                  <LinkBlock style={{ marginTop: '0' }} target="_blank" href={`https://explorer.stacks.co/address/${royalty.address}?chain=${process.env.REACT_APP_STACKS_NETWORK}`} variant="body2" rel="noopener">
                                    <Typography variant="body2" style={{ height: '32px', padding: '0 9px', borderRadius: '21px', border: 'solid 1px #eee', backgroundColor: 'rgb(247, 247, 247)', display: 'flex', textDecoration: 'none', color: 'black', alignItems: 'center', overflow: 'hidden', width: '185px' }}>
                                      <img style={{ height: '20px', marginRight: '10px' }} src={Avatar} alt="Layer Avatar" /> {royalty.royalty} % / {royalty.address.substring(0, 5) + '...' + royalty.address.substr(royalty.address.length - 5)}
                                    </Typography>
                                  </LinkBlock>
                                </RoyaltyContainer>
                              </div>
                              )
                            }
                            
                          })}
                            </div>
                      </div>
                    }
                    {(royalties[0] && royalties[0].address.length > 0) &&
                      <div>
                        <Typography variant="body2" className={classes.sub_title} style={{gridColumn: '1 / -1', marginBottom: '5px', textAlign: 'left', color: '#000', fontWeight: 'bold'}}>
                          Secondary Split
                          </Typography>
                          <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 200px)'}}>
                          {royalties.map((royalty) => {
                            if (royalty.address.length > 0) {
                              return (
                                <div style={{ position: 'relative', marginBottom: '5px' }}>
                                  <RoyaltyContainer style={{ display: 'grid', marginBottom: '0px', gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 89px))', gridGap: '5px' }}>
                                    <LinkBlock style={{ marginTop: '0' }} target="_blank" href={`https://explorer.stacks.co/address/${royalty.address}?chain=${process.env.REACT_APP_STACKS_NETWORK}`} variant="body2" rel="noopener">
                                      <Typography variant="body2" style={{ height: '32px', padding: '0 9px', borderRadius: '21px', border: 'solid 1px #eee', backgroundColor: 'rgb(247, 247, 247)', display: 'flex', textDecoration: 'none', color: 'black', alignItems: 'center', overflow: 'hidden', width: '185px' }}>
                                        <img style={{ height: '20px', marginRight: '10px' }} src={Avatar} alt="Layer Avatar" /> {royalty.royalty} % / {royalty.address.substring(0, 5) + '...' + royalty.address.substr(royalty.address.length - 5)}
                                      </Typography>
                                    </LinkBlock>
                                  </RoyaltyContainer>
                                </div>
                              )
                            }
                          })}
                        {/* <div>
                          <InputLabel style={{marginBottom: '5px'}} htmlFor={`nft-royalty${index}`}>STX Address</InputLabel>
                          <Input className={`address_input address_input_${index}`} disabled={royalty.premium} value={royalties[index].address} id={`nft-royalty${index}`} onChange={(e) => handleNameChange(e, index)} label="Address" name={`address${index}`} />
                        </div>
                        <div>
                          <InputLabel style={{marginBottom: '5px'}} htmlFor={`nft-percentage${index}`}>Percentage</InputLabel>
                          <Input className="percentage_input" disabled={royalty.premium} value={royalties[index].royalty} id={`nft-percentage${index}`} onChange={(e) => handleNameChange(e, index)} label="Percentage" name={`percentage${index}`} />
                        </div>
                        {royalty.premium ?
                          <Check />
                          :
                          <HighlightOff onClick={(e) => removeRoyalty(e, index)} />
                        }
                        {royalty.own && 
                            <Typography style={{gridColumn: '1 / -1', textAlign: 'left', fontSize: '11px', marginTop: '-10px'}} variant="body2">Your Stacks Wallet Address. The percentage entered will be the royalty amount you’ll receive at this address on any secondary sale of your NFT.</Typography>
                          } */}
                          </div>
                          </div>
                    }
                    {/* holder */}
                {false && royalties.map((royalty, index) => (
                  <Royalty style={royalty.premium && { opacity: '.6' }}>
                    <div>
                      <InputLabel style={{ marginBottom: '5px' }} htmlFor={`nft-royalty${index}`}>STX Address</InputLabel>
                      <Input className={`address_input address_input_${index}`} disabled={royalty.premium} value={royalties[index].address} id={`nft-royalty${index}`} onChange={(e) => handleNameChange(e, index)} label="Address" name={`address${index}`} />
                    </div>
                    <div>
                      <InputLabel style={{ marginBottom: '5px' }} htmlFor={`nft-percentage${index}`}>Percentage</InputLabel>
                      <Input className="percentage_input" disabled={royalty.premium} value={royalties[index].royalty} id={`nft-percentage${index}`} onChange={(e) => handleNameChange(e, index)} label="Percentage" name={`percentage${index}`} />
                    </div>
                    {royalty.premium ?
                      <Check />
                      :
                      <HighlightOff style={{alignSelf: 'center'}} onClick={(e) => removeRoyalty(e, index)} />
                    }
                    {royalty.own &&
                      <Typography style={{ gridColumn: '1 / -1', textAlign: 'left', fontSize: '11px', marginTop: '-10px' }} variant="body2">Your Stacks Wallet Address. The percentage entered will be the royalty amount you’ll receive at this address on any secondary sale of your NFT.</Typography>
                    }
                  </Royalty>
                ))}
                    {/* holder */}
                {true ?
                      <ButtonCollaborator onClick={() => setRoyaltiesScreen(true) } >
                        {/* <ButtonCollaborator onClick={addCollaborator} > */}
                        {( (royalties[0] && royalties[0].address.length > 0) || firstRoyalties.length > 0) ? <Edit style={{width: '20px', marginRight: '5px'}} /> : <AddCircleOutline style={{width: '20px', marginRight: '5px'}} />}
                        
                        {( (royalties[0] && royalties[0].address.length > 0) || firstRoyalties.length > 0) ? 'Edit Royalty' : 'Add Royalty'}
                      </ButtonCollaborator>
                      :
                      <ButtonCollaborator onClick={addCollaborator} >
                  <AddCircleOutline style={{ width: '20px', marginRight: '5px' }} />
                  Add Royalty
                </ButtonCollaborator>
                }
                
                <Auction style={{height: '35px'}}>
                    <FormControlLabel
                      color="primary"
                      control={
                        <Switch
                          checked={state.for_sell}
                          onChange={handleSell}
                          color="primary"
                          name="auction"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          }
                          label="Sell NFT"
                        />
                    </Auction>
                    {/* holder */}
                  {(true && state.for_sell) &&
                    <Auction style={{height: '35px'}}>
                      <Typography>Buy Now</Typography>
                      <FormControlLabel
                        color="primary"
                        control={
                          <Switch
                            checked={state.auction}
                            onChange={handleAuction}
                            color="primary"
                            name="auction"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="Auction"
                      />
                    </Auction>
                  }
                {(state.auction && state.for_sell) &&
                  <>
                    <InfoView>
                        <TypoLabel variant="body2">Auction length  <IconInfo className="info_icon">i</IconInfo>
                        <div className="info_details" >
                          <Typography variant="body2">
                            This is the length of time when your auction will be live on heylayer’s marketplace. Your auction will be live as soon as it is successfully minted.
                          </Typography>
                              </div>
                        </TypoLabel>
                      </InfoView>
                    <RadioGroup
                      aria-label="auction_"
                      defaultValue={state.auction_duration}
                      name="auction_duration"
                      style={{ color: '#000', flexDirection: "row", height: '35px' }}
                      onChange={(e) => selectAuctionDuration(e)}
                  className="auction_options"
                  label="Auction length"
                      >
                        <FormControlLabel value="24" control={<Radio />} label="24 hours" />
                        <FormControlLabel value="72" control={<Radio />} label="3 days" />
                        <FormControlLabel value="120" control={<Radio />} label="5 days" />
                        <FormControlLabel value="168" control={<Radio />} label="7 days" />
                    </RadioGroup>
                    </>
                  }
                  <CheckboxRights>
                    <input
                      id="publish_rights"
                      name="publish_rights"
                      type="checkbox"
                      checked={state.publish_rights}
                      onChange={handleCheckRights}
                    />
                    <InputLabel htmlFor="publish_rights">I have read the terms of service and have the right to mint this artwork</InputLabel>
                  </CheckboxRights>
                  <CheckboxRights style={{marginTop: '-13px'}}>
                    <input
                      id="explicit_content"
                      name="explicit_content"
                      type="checkbox"
                      checked={state.explicit_content}
                      onChange={handleCheckRights}
                    />
                    <InputLabel htmlFor="explicit_content" style={{lineHeight: '17px'}}>Mark as Explicit Content</InputLabel>
                  </CheckboxRights>
              </MetadataForm>
              
              <ButtonContinue
                disabled={!state.publish_rights }
                style={(state.publish_rights) ? { opacity: '1', cursor: 'pointer' } : { opacity: '0.5', cursor: 'default' }}
                mr={2}
                variant="contained"
                color="primary"
                onClick={() => mintCollection(file_id, state, attributes, royalties, colec_id, fileData)}>
                Continue
              </ButtonContinue>
            </DialogContainerText>
              </DialogContainer>
            </>
            :
            <DialogContainer style={{width: '525px'}}>
                <DialogContainerText>
                  <Typography variant="h4" color="primary">Add Royalties</Typography>
                    <GoBack style={{left: '20px'}} color="primary" onClick={() => setRoyaltiesScreen(false)}>
                      <ArrowBack />
                    </GoBack>
                <AppBar position="static">
                  <InfoView>
                        <TypoLabel2 style={{ position: 'absolute', marginTop: '45px', right: '50%', marginRight: '60px' }} variant="body2"><IconInfo>i</IconInfo>
                        <div className="info_details" style={{width: '320px', right: '-320px'}} >
                          <Typography variant="body2">
                            This is the royalty split from the first sale. You can split up to a maximum of 90% of the proceeds by providing the STX address here. Heylayer has a 10% transaction fee.
                          </Typography>
                              </div>
                        </TypoLabel2>
                      </InfoView>
                    <InfoView>
                        <TypoLabel2 style={{ position: 'absolute', marginTop: '45px', right: '-20px', marginRight: '60px' }} variant="body2"><IconInfo>i</IconInfo>
                        <div className="info_details" style={{width: '320px', right: '0px'}} >
                          <Typography variant="body2">
                            This is the royalty split for all sales after the first sale. Setting this at 5% means that you will receive 5% of the sale price every time your NFT is sold. You can split up to a maximum of 10% of proceeds on secondary sales. Heylayer has a 10% transaction fee.
                          </Typography>
                              </div>
                        </TypoLabel2>
                      </InfoView>
                    
                    <Tabs
                      value={value}
                      onChange={handleChangeTab}
                      indicatorColor="primary"
                      textColor="inherit"
                      variant="fullWidth"
                    >
                      <Tab label="First Split" {...a11yProps(0)}>
                        <TypoLabel style={{ marginBottom: '-5px' }} variant="body2" htmlFor="nft-url">External URL <IconInfo>i</IconInfo></TypoLabel>
                      </Tab>
                      <Tab label="Secondary Split" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <div style={{width: '100%'}}>
                  <SwipeableViews
                    axis={'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                  >
                    <TabPanel value={value} index={0} dir={''}>
                      {firstRoyalties.map((royalty, index) => (
                      <Royalty style={royalty.premium && { opacity: '.6' }}>
                        <div>
                          <InputLabel style={{marginBottom: '5px'}} htmlFor={`nft-royalty${index + royalties.length}`}>STX Address</InputLabel>
                          <Input className={`address_input address_input_${index + royalties.length}`} disabled={royalty.premium} value={firstRoyalties[index].address} id={`nft-royalty${index + royalties.length}`} onChange={(e) => handleFirstRoyalty(e, index)} label="Address" name={`address${index + royalties.length}`} placeholder="SP..."/>
                        </div>
                        <div>
                          <InputLabel style={{marginBottom: '5px'}} htmlFor={`nft-percentage${index + royalties.length}`}>Percentage</InputLabel>
                          <Input className="percentage_input" type="number" disabled={royalty.premium} value={firstRoyalties[index].royalty} id={`nft-percentage${index + royalties.length}`} onChange={(e) => handleFirstRoyalty(e, index)} label="Percentage" name={`percentage${index + royalties.length}`} placeholder="00"/>
                        </div>
                        <PercentageText variant="body2">%</PercentageText>
                        {royalty.premium ?
                          <Check />
                          :
                          <HighlightOff style={{alignSelf: 'center'}} onClick={(e) => removeFirstRoyalty(e, index)} />
                        }
                        {royalty.own && 
                            <Typography style={{gridColumn: '1 / -1', textAlign: 'left', fontSize: '11px', marginTop: '-10px'}} variant="body2">Your Stacks Wallet Address. The percentage entered will be the royalty amount you’ll receive at this address on any secondary sale of your NFT.</Typography>
                          }
                        <p className="error_label"></p>
                        <p className="error_label2"></p>
                      </Royalty>
                    ))}
                  
                  
                      <ButtonCollaborator style={{ display: 'flex', marginTop: '20px' }} onClick={addFirstCollaborator} >
                        <AddCircleOutline style={{width: '20px', marginRight: '5px'}} />
                        Add More
                        </ButtonCollaborator>
                        <ButtonContinue2
                        mr={2}
                        variant="contained"
                      color="secondary"
                        onClick={() => setValue(1)}>
                      Next
                    </ButtonContinue2>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={''}>
                      {royalties.map((royalty, index) => (
                      <Royalty style={royalty.premium && { opacity: '.6' }}>
                        <div>
                          <InputLabel style={{marginBottom: '5px'}} htmlFor={`nft-royalty${index}`}>STX Address</InputLabel>
                          <Input className={`address_input address_input_${index}`} disabled={royalty.premium} value={royalties[index].address} id={`nft-royalty${index}`} onChange={(e) => handleNameChange(e, index)} label="Address" name={`address${index}`} placeholder="SP..."/>
                        </div>
                        <div>
                          <InputLabel style={{marginBottom: '5px'}} htmlFor={`nft-percentage${index}`}>Percentage</InputLabel>
                          <Input className="percentage_input" type="number" disabled={royalty.premium} value={royalties[index].royalty} id={`nft-percentage${index}`} onChange={(e) => handleNameChange(e, index)} label="Percentage" name={`percentage${index}`} placeholder="00"/>
                        </div>
                        <PercentageText variant="body2">%</PercentageText>
                          {royalty.premium ?
                            <Check />
                            :
                            <HighlightOff style={{alignSelf: 'center'}} onClick={(e) => removeRoyalty(e, index)} />
                        }
                        {royalty.own && 
                            <Typography style={{gridColumn: '1 / -1', textAlign: 'left', fontSize: '11px', marginTop: '-10px'}} variant="body2">Your Stacks Wallet Address. The percentage entered will be the royalty amount you’ll receive at this address on any secondary sale of your NFT.</Typography>
                          }
                        <p className="error_label"></p>
                        <p className="error_label2"></p>
                      </Royalty>
                    ))}
                  
                  
                      <ButtonCollaborator style={{ display: 'flex', marginTop: '20px' }} onClick={addCollaborator} >
                        <AddCircleOutline style={{width: '20px', marginRight: '5px'}} />
                        Add More
                        </ButtonCollaborator>
                        <ButtonContinue2
                        mr={2}
                        variant="contained"
                      color="secondary"
                        onClick={() => setRoyaltiesScreen(false)}>
                      Save
                    </ButtonContinue2>
                    </TabPanel>
                    </SwipeableViews>
                    
                  </div>
                </DialogContainerText>
              </DialogContainer>
          :
          <>
            {(loading && !tx_hash) ?
              <>
                <DialogTitle id="alert-dialog-title2">
                  Minting NFT
                </DialogTitle>
                <DialogContainer style={{width: '415px'}}>
                  <DialogContainerText style={{ margin: '-50px -50px -30px' }}>
                    <LoadingLocker />
                  </DialogContainerText>
                </DialogContainer>
              </>
              :
              tx_hash.toString().includes('Error') ?
                <DialogContainer style={{width: '415px', display: 'flex', justifyContent:'center', alignItems:'center'}}>
                  <DialogContainerText >
                    <Typography style={{ zIndex: '20', marginBottom: '10px', marginTop: '20px' }} variant="h4" color="primary">{tx_hash}</Typography>
                    <ButtonContinue style={{ zIndex: '20', width: '80px', backgroundColor: '#fff', color: '#000', border: 'solid 1px #d7dde4', borderRadius: '30px', marginTop: '20px'}} mr={2} variant="contained" color="primary" onClick={() => handleClose()}>
                      Close
                    </ButtonContinue>
                  </DialogContainerText>
                </DialogContainer>
                :
              <>
                <DialogContainer style={{width: '525px'}}>
                  <Confetti style={{ width: '100%', height: 'calc(100%)' }} />
                  <DialogContainerText>
                    <Typography style={{zIndex: '20', marginBottom: '10px', marginTop: '20px'}} variant="h4" color="primary">NFT Minted Successfully</Typography>
                    <Typography variant="body2" style={{ fontSize: '54px', zIndex: '20', color: '#000', marginBottom: '10px' }}>🎉</Typography>
                    <ButtonContinue style={{ zIndex: '20', width: '80px', backgroundColor: '#fff', color: '#000', border: 'solid 1px #d7dde4', borderRadius: '30px', marginTop: '20px'}} mr={2} variant="contained" color="primary" onClick={() => handleClose()}>
                      Close
                    </ButtonContinue>
                  </DialogContainerText>
                </DialogContainer>
              </>
            }
          </>
        }
      </Dialog>
    </>
  )
}

export default MintToCollection;