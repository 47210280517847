// Firebase Config
import firebase from 'firebase'
const config = {
  apiKey: "AIzaSyCTy8N57LMnNj8YAYB5Nr9NrZLJbqdSZrM",
  authDomain: "layer-abe5c.firebaseapp.com",
  projectId: "layer-abe5c",
  storageBucket: "layer-abe5c.appspot.com",
  messagingSenderId: "837597709434",
  appId: "1:837597709434:web:90c6f704d0544b296da7c3"
};
firebase.initializeApp(config);
export default firebase;
